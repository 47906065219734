// _base-colours.scss
// Greenman Webdesgns
//

$_vr-border-color: $border-color;

$grey-color-shade: shade($grey-color, $darken);
$grey-color-tint: tint($grey-color, $lighten);

$primary-color-shade: shade($primary-color, $darken);
$primary-color-tint: tint($primary-color, $lighten);

$secondary-color-shade: shade($secondary-color, $darken);
$secondary-color-tint: tint($secondary-color, $lighten);

$navigation-background-color-shade: shade($navigation-background-color, $darken);
$navigation-background-color-tint: tint($navigation-background-color, $lighten);


// Links
$link-color: $secondary-color;
$link-color-visited: $secondary-color-shade;
$link-color-hover: $secondary-color-tint;
$link-color-active: $secondary-color-tint;

// Buttons
$button-color: $navigation-color;

// Menus
$menu-color: $link-color;
$menu-color-visited: $link-color-visited;
$menu-color-hover: $link-color-hover;
$menu-color-active: $link-color-active;
$menu-border-color: $border-color;


// Navigation
$navigation-color-active: $header-color;
$navigation-color-hover: $navigation-color;
$navigation-color-link-active: $navigation-background-color-tint;
$navigation-background-color-active: $navigation-background-color-tint;
$navigation-background-color-hover: $navigation-background-color-tint;

// Tabs
$tabs-color: $navigation-color;
$tabs-color-active: $navigation-color-active;
$tabs-border-color: $button-border-color;
$tabs-background-color: $primary-color-shade;
$tabs-background-color-active: $primary-color-tint;
$tabs-background-color-hover: $primary-color-tint;
$tabs-background-color-focus: $primary-color-tint;

// Headings
$heading-link-color: $heading-color;
$heading-link-color-visited: $heading-link-color;
$heading-link-color-hover: lighten($heading-link-color, 20);
$heading-link-color-active: $heading-link-color;

// Tables
$table-border-color: $primary-color;
$th-background-color: $primary-color;

// Header region
$header-link-color-hover: lighten($header-color, $lighten);
$header-link-color-active: lighten($header-color, $lighten);

// Footer Links

// Forms
$input-color: $font-color;

// Regions
$header-link-color: $header-color;
$unpublished-background-color: $error-background-color;


//
// Regions
//
html {
	background-color: $html-background-color;
}

body {
	color: $font-color;
	background-color: $body-background-color;
}
.l-content img {
	background-color: $image-background-color;
}
mark {
	color: $mark-color;
	background-color: $mark-background-color;
}
#toolbar-wrapper {
	background-color: $toolbar-background-color;
	color: $toolbar-color;
}
#page-wrapper {
	background-color: $page-background-color;
}
.l-main {
	background-color: $main-background-color;
}

.views-row {
	background-color: $content-background-color;
}
#header-wrapper {
	background-color: $header-background-color;
	color: $header-color;
}
.l-navigation {
	background-color: $navigation-background-color;
	color: $navigation-color;
}
#navigation-wrapper {
	background-color: $navigation-wrapper-background-color;
}
#footer-wrapper {
	color: $footer-color;
	background-color: $footer-background-color;
}


.l-region--sidebar-first {
	background-color: $sidebar-first-background-color;
}
.l-region--sidebar-second {
	background-color: $sidebar-second-background-color;
}


//
// Messages
//
.messages, .error {
	background-repeat: no-repeat;
	background-position: $small-spacing $small-spacing;
}
// Notification messages
.messages, .status, .messages--status {
	color: $status-color;
	background-color: $status-background-color;
	background-image: url("../images/misc/message-24-ok.png");
	@include border-3d($status-border-color);
}
// Warning messages
.warning {
	color: $warning-color;
}
.messages--warning, tr.warning {
	color: $warning-color;
	background-color: $warning-background-color;
	background-image: url("../images/misc/message-24-warning.png");
	@include border-3d($warning-border-color);
}
// Error messages
.messages--error, tr.error {
	color: $error-color;
	background-color: $error-background-color;
	background-image: url("../images/misc/message-24-error.png");
	@include border-3d($error-border-color);
}

.error {
	color: $error-color;
	background-color: $error-background-color;
}

//
// Links
//
a {
	color: $link-color;
  text-decoration: none;
	&:visited {
		color: $link-color-visited;
	}
  &:active,
  &:focus,
  &:hover {
		color: $link-color-hover;
  }
}

a.active {
	color: $link-color-active;
}

.l-header, .l-region--header {
	color: $header-color;
	h1, h2, h3 {
		color: $header-color;
	}
	a {
		color: $header-link-color;
  	&:hover {
			color: $header-link-color-hover;
		}
		&:active, .active {
			color: $header-link-color-active;
		}
	}
}

//
// Buttons
//
.button,
#{$all-buttons} {
	cursor: pointer;
  appearance: none;
  border: 0;
  color: $button-color;
	@include background-color-hover-gradient($button-background-color);

	&:hover, 
	&:visited {
		color: $button-color;
		text-decoration: none;
	}
  &:focus {
		border-width: 2px;
  }
	&:active {
		background-color: lighten($button-background-color, $lighten);
	}
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    &:hover {
			@include background-color-gradient($grey-color); 
    }
  }
}

//
// Menus
//

.l-navigation {
	.icon:hover {
  	background-color: $navigation-background-color-hover;
  }
}
.l-region--toolbar,
.l-region--navigation {
	a {
  	color: $button-color;
		text-decoration: none;
		border: solid 1px transparent;
  	&:hover {
   		background-color: $navigation-background-color-hover;
			@include border-3d($navigation-background-color-hover);
		}
	}
	a.active, a:active {
		color: $navigation-color-active;
		background-color: $navigation-background-color-active;
		@include border-3d($navigation-background-color-active);
	}
}

//.l-region--footer {
//		a:link, a:visited {
//			color: $footer-color;
//		}
//		// a:visited
//		a:hover {
//			color: $footer-color-hover;
//			background-color: $footer-background-hover;
//		}
//		a.active:hover {
//			color: $footer-color-active;
//			background-color: $footer-background-active-hover;
//		}
//		a:active {
//			color: $footer-color-link-active;
//			background-color: $footer-background-link-active;
//		}
//		a.active, a.active-trail { // Active Drupal Menu Item
//			color: $footer-color-active;
//			background-color: $footer-background-active;
//		}
//}

//
// Tabs
//
.ui-tabs-nav, 
ul.tabs--primary, 
.vertical-tabs ul.vertical-tabs-list {
	color: $tabs-color;
	border-color: $border-color;
	background-image: linear-gradient(transparent 50%, rgba(0,0,0,0.05));
  li, li.selected, li.ui-state-default {
		color: $tabs-color;
    a, a:visited {
			color: $tabs-color;
//			border-color: $border-color;
			@include border-3d($tabs-border-color);

			@include background-color-hover-gradient($tabs-background-color);
			strong {
				color: $tabs-color;
			}
    }
		a.active, a:active {
			color: $tabs-color-active;
			@include background-color-hover-gradient($tabs-background-color-active);
		}
  }
}

.tabs--secondary a {
	@extend a;
	color: $tabs-color;
	@include background-color-hover-gradient($tabs-background-color);
}
.tabs--secondary a.active {
	@extend a.active;
	color: $tabs-color;
	@include background-color-hover-gradient($tabs-background-color-active);
}
.tabs-secondary a:hover, .tabs-secondary a:focus {
	color: $tabs-color;
	@include background-color-hover-gradient($tabs-background-color-focus);
}

//
// Tables
//
table {
	border-collapse: separate;
	border-width: 1px;
	border-style: solid;
	@include border-3d($table-border-color);
}
//caption {
//	color: $navigation-color;
//	background-color: $primary-color-shade;
//}
//caption,
//table, tr, th, td {
th, td {
	border-style: solid;
	border-color: transparent;
}
th {
	color: $navigation-color;
	@include border-3d($primary-color);
	a, a.active {
		color: $navigation-color;
	}
	background-color: $primary-color;
}
th.active {
	background-color: $primary-color-tint;
}
tr:nth-child(odd), 
tr.odd {
	background-color: $oddrow-background-color;
	td.active {
		color: $navigation-color;
		background-color: $shade;
	}
}
tr:nth-child(even), 
tr.even {
	background-color: $evenrow-background-color;
	th {
		background-color: $primary-color; // Match contrast of alpha elements
	}
	td.active {
		color: $navigation-color;
		background-color: $tint;
	}
}

// 
// Forms
//

fieldset,
input:not([type="radio"]):not([type="checkbox"]),
select,
textarea, 
fieldset {
	color: $input-color;
	background-color: $input-background-color;
	//@include border-3d($input-border-color);
	@include border-3d($button-border-color);
}

// Buttons
#{$all-buttons}, input:not([type=radio]):not([type=checkbox]), input[type="submit"] {
	@include border-3d($button-border-color);
}


//
// Headings
//
.l-main {
h1, h2, h3, h4, h5 {
	color: $heading-color;
}

h1, h2, h3 {
	a {
		color: $heading-link-color;
		&:visited {
			color: $heading-link-color-visited;
		}
		&:hover {
			color: $heading-link-color-hover;
		}
		&:active, a.active {
			color: $heading-link-color-active;
		}
	}
}
}

.node-unpublished {
	background-color: $unpublished-background-color;
}

.resizable-textarea .grippie {
  background-color: $shade;
  //border-color: $border-color;
	@include border-3d($button-border-color);
}

blockquote {
	color: $heading-color;
	background-color: $blockquote-background-color;
	border-color: $blockquote-border-color;
}
