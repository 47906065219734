// Hide navigation elements from print media
@media print {
	form, .tabs, .field--vote, .links,
	ul.breadcrumb,
	#block-search-form,
	#navigation-wrapper {
		display: none;
	}
	
}

// A4 page
//@media print and (min-width: 30em) {
//	@page {
//		margin: 2cm;
//	}
//}
