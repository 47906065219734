//
// _vars-breakpoints.scss
// Greenman Webdesigns
//

@import "../abstractions/_mixin-media.scss";

// Maximum paragraph width
$p-max-width: 40em;
//$max-width: 16px * 40; 
$max-width: 70em;
//$max-width: 80em;

$all: "0";
$tiny: "20em";    // 320px
$small: "30em";   // 480px
$a4: "30em"; // 800px A4=793.80079PX
$narrow: "45em";  // 720px
$normal: "60em";  // 960px
$large: "75em";   // 1200px
$wide: "90em";   // 1440px
//$wide: "95em";   // 1520px
$hd: "120em";    // 1920px
