.giftvoucher img, .giftvoucher a {
	max-height: rhythm(4);
	width: auto;
	margin: 0px;
	padding: 0px;
}

//.giftvoucher div {
//	text-align: center;
//}
