//
// _fonts-base.scss
// Greenman Webdesigns
//

html {
	-webkit-font-smoothing: antialiased;
}

body {
	font-family: $base-font;
}


.heading,
h1, h2, h3, h4, h5 {
	font-family: $heading-font;
	text-rendering: optimizeLegibility; 
}

.l-header, .l-region--navigation {
//	font-family: $header-font;
	text-rendering: optimizeLegibility; 
}

p {
  hyphens: auto;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

.l-content {
	font-family: $content-font;
}

a.active {
  font-weight: bold;
}

.l-region--navigation {
  ul, ul.menu {
    li, li.leaf {
      font-weight: bold;
		}
	}
}

.fieldset-legend {
  font-weight: bold;
}

// Buttons
.button,
#{$all-buttons} {
  font-weight: bold;
};

blockquote {
  font-family: $sans-serif-semibold;
}

.form-actions {
	font-family: $base-font;
}

