a.ctools-ajaxing,
input.ctools-ajaxing,
button.ctools-ajaxing,
select.ctools-ajaxing {
  padding-right: 18px !important;
  background: url(../images/misc/throbber-animated.svg) right center no-repeat;
}

div.ctools-ajaxing {
  float: left;
  width: 18px;
  background: url(../images/misc/throbber-animated.svg) center center no-repeat;
}
