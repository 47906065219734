.node--teaser {
	@include vr(
		$margin-bottom: 1
	);
	.field--image {
		float: left;
		a {
			display: block;
			height: 96px;
			margin: 0;
			padding: 0;
		}
	}
	.field-body p {
		text-align: justify;
	}
	img {
		float: left;
		margin: 0 1em 0.5em 0;
	}
}

.node--full {
	.field--image img, img.media-image {
		float: left;
		margin: 0 1em 0.5em 0;
	}
}

.node--page--full,
.node--practitioner--full,
.node--therapy--full {
	img {
		margin: 0 1em 0.5em 0;
		margin: 1em;
	}
}
