//
// _fonts-vars.scss
// Greenman Webdesigns
//

//@import "../../node_modules/bourbon/app/assets/stylesheets/_bourbon.scss";
@include font-face("freesans", "../fonts/freesans/freesans-webfont");
@include font-face("charter", "../fonts/charter/charter_regular-webfont");
@include font-face("charter-bold", "../fonts/charter/charter_bold-webfont");

$serif: "charter", $font-stack-georgia;
$sans-serif: "freesans", $font-stack-helvetica;
$sans-serif-semibold: $font-stack-lucida-grande;

$heading-font: "charter-bold", $serif;
$base-font: $sans-serif;

$content-font: $base-font;
$header-font: $sans-serif;

// Define font styles
.serif {
  font-family: $serif;
}
.sans-serif {
  font-family: $sans-serif;
}

