#bookeo_position {
	iframe {
		height: 1158px;
		min-width: 640px;
	}
}
.booking-form-link {
	@include vr(
		$font-size: -1
	);
}
