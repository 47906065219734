a.ckeditor_links {
	display: block;
	clear: both;
	@include vr(
		$font-size: -1
	);
	
}
.better-comments {
	.form-item {
		margin: 0;
	}
	div[id*="edit-comment-body"] {
		width: 100%;
	}
}

.better-comments-confirm, .preview {
	box-shadow: none;
	min-height: auto;
	padding: 0;
	width: auto;
}

div.comment-body {
	width: 100%;
	border-radius: $border-radius;
	@include vr(
		$margin: 0 0 1 0
	);
	a.username {
		display: block;
		width: 100%;
		clear: both;
	}
	.field--images {
		float: left;
		margin-right: 4%;
		max-width: 40%;
	}
}

.indented .comment-body {
	width: 100%;
}

div[class*="indented"] {
	width: 96%;
	margin-left: 4%;
}

.comment__links {
//	text-transform: capitalize;
	@include vr(
		$font-size: -1
	);
}

.comment-data time {
	font-style: italic;
	display: block;
	width: 100%;
	clear: both;
	@include vr(
		$margin: 1 0 0 0
	);
}
