@mixin border-3d($color, $width: 1px, $style: solid) {
	border-color:
		tint($color, $lighten)
		$color
		shade($color, $darken)
		shade($color, ($darken / 2));
	border-width: $width;
	border-style: $style;
	box-shadow: $shadow;
}
