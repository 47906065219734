//
// _offcanvas-layout.scss
//
$offcanvas-menu-width: 15em;
$offcanvas-menu-min-height: 120em;

//
// Menu NOT VISIBLE canvas settings
//

.modal-icon-wrapper {
	float: left;
  width: $icon-width;
	height: $icon-height;
}
.l-filters-wrapper {
	@include vr(
		$margin: 0.5 0 0.5 
	)
}

// Match modal navigation icon formating to navigation bar
#nav-modal-wrapper {
	@include vr(
		$margin: 0.25 0.5 0 0
	);
  border-bottom: solid 1px transparent;
}
//#actions-modal-wrapper {
//	width: 100%;
//}

.js .modal-menu, 
.js .l-filters {
	float: left;
	@include vr(
		$margin: 0 0.25 0.25 0
	);
	position: relative;
	max-height: $icon-height; // IMPORTANT: essential for ajax visibility toggle to work
	max-width: $icon-width;
	overflow: hidden;
//	background-color: transparent;

	// Get those blasted things out of the way when the icon canvas is minimised
	.contextual-links-wrapper {
		display: none !important;
	}
}

//
// Menu IS VISIBLE canvas settings
//

#modal-background.is-visible {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background-color: $tint;
}

.js .l-filters.is-visible {
	width: auto;
	height: auto;
	max-width: 100%;
}

.js .modal-menu.is-visible {
	position: absolute;
	width: $offcanvas-menu-width;
	max-width: 100%;
	margin-left: -0.1em;
	.inner {
		text-shadow: none;
		padding: 0 8.3% 0.5em;
	}

	// Undor navbar link color formatting
	color: $font-color;
	h1, h2, h3, h4, h5 {
		color: $heading-color;
		margin: 0;
	}
	ul.menu {
		@include vr(
			$margin: 0 0 1em 1em
		);
		margin: 0 0 1em 1em;
		ul.menu {
			@include vr(
				$margin: 0 0 1 0.25
			)
		}
	a {
		color: $font-color;
  	color: $link-color;
  	text-decoration: none;
  	&:visited {
    	color: $link-color-visited;
  		}
  	&:active,
  	&:focus,
  	&:hover {
    	color: $link-color-hover;
 		}
	}
	}
	box-shadow: 0 0 1px rgba(0,0,0,0.5), 3px 3px 15px 3px rgba(0,0,0,0.1);
}

.js .l-filters.is-visible {
	width: 100%;
	box-shadow: $shadow;
	.inner {
		@include vr(
			$padding: 0 0.5,
			$margin: 0 0 1.5
		);
		h2 {
			margin: 0;
		}
	}
}

.js .l-filters.is-visible, 
.js .modal-menu.is-visible {
	overflow: visible;
	max-height: $offcanvas-menu-min-height; // IMPORTANT for ajax visibility toggle to work
	background-color: $main-background-color;
	border-radius: $border-radius;
	@include border-3d($titlebar-background-color);
	box-shadow: $shadow;
	.titlebar {
		@include color-header-nohover($titlebar-background-color);
		border-radius: $border-radius $border-radius 0 0;
		width: 100%;
		@include clearfix;
	}
	.titlebar-title {
		display: block;
		color: white;
		font-weight: bold;
		@include vr(
			$padding: 0.3 0.25 0
		)
		float: left;
	}
}

//
// Icon settings
//

//.js #actions-modal .icon-menu, 
//.js #filters .icon-search {
//	@include color-header($navigation-wrapper-background-color);
//}

// Hide "menu icon" when open
.js .modal-menu.is-visible .icon-menu,
.js .l-filters.is-visible .icon-search {
  display: none;
}
