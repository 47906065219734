.flexslider {
	margin: 0;
	border: 0;
	box-shadow: none;
	@include clearfix;
}

.flex-control-thumbs {
	margin: 0;
	height: 12em;
	overflow-y: auto;
	resize: both;
	@include vr(
		$margin: 0.25 0
	);
	li {
		@include vr(
			$padding: 0.25 0.125
		);
	}
}

.flex-control-paging li a.flex-active {
	background-color: $primary-color;
}
