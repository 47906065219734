//
// __vetical-rhythm-vars.scss 
// Greenman Webdesigns
//

@import "../../node_modules/vertical-rhythm-reset/dist/vertical-rhythm-reset";
$vr-grid-color: grey;
// Show vertical rhythm debug grid or not
$debug-vr: false;

// Maximum paragraph width
$p-max-width: 40em;

// Icon size
$icon-width: 2em;
$icon-height: 2em;
$icon-margin: 0.25em;

$avatar-width: 3em;
$avatar-height: 3em;

//
// Vertical rhythm
//
//$border-box-sizing: true !default;

// Scaling Variables
// minor-second    : 1.067, // Ratio = 15:16
// major-second    : 1.125, // Ratio = 8:9
// minor-third     : 1.2,   // Ratio = 5:6
// major-third     : 1.25,  // Ratio = 4:5
// perfect-fourth  : 1.333, // Ratio = 3:4
// augmented-fourth: 1.414, // Ratio = 1:√2
// perfect-fifth   : 1.5,   // Ratio = 2:3
// minor-sixth     : 1.6,   // Ratio = 5:8
// golden          : 1.618, // Ratio = 1:1.618
// major-sixth     : 1.667, // Ratio = 3:5
// minor-seventh   : 1.778, // Ratio = 9:16
// major-seventh   : 1.875, // Ratio = 8:15
// octave          : 2      // Ratio = 1:2
// $major-tenth		 : 2.5;
// $major-eleventh : 2.667;
// $major-twelfth  : 3;
// $double-octave  : 4;

//$vr-line-height: $major-third;
$vr-modular-scale: major-third;
$vr-line-height: $perfect-fourth;
//$vr-modular-scale: $minor-third;
$vr-helpers: false;

$base-spacing: 0.5em;
$small-spacing: $base-spacing / 2;

//$vr-height: 2em;
//$vr-helpers: false;

$vr-font-size: 16px;
$vr-breakpoints: (
    60em: ( // 960px
        font-size    : 18px,
        line-height  : 1.4,
        modular-scale: major-third,
        grid-color   : purple
    ),
    90em: ( // 1440px
        font-size    : 20px,
        line-height  : 1.3,
        modular-scale: perfect-fourth,
        grid-color   : blue
    )
);
