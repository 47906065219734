// Set the icon background, this is the level that colors on hover etc.
.icon {
  height: $icon-height;
  width: $icon-width;
	padding: 0;
	color: transparent;
	border-radius: $border-radius;
	text-shadow: none;	
	cursor: pointer;
	overflow: hidden;
	background-size: cover;
	a, a.active {
		display: block;
		margin: 0 !important;
		padding: 0 !important;
  	height: $icon-height;
  	width: $icon-width;
		color: transparent;
		&:hover, 
		&:active {
			color: transparent;
		}
		background-size: cover;
	}
}

.icon-small {
	@extend .icon;
  width: $icon-width / 1.5;
  height: $icon-height / 1.5;
	a {
  	width: $icon-width / 1.5;
  	height: $icon-height / 1.5;
	}
	@include vr(
		$margin: 0 0.25 0.25 0
	);
}

.tabledrag-handle {
	//float: left;
	@extend .icon;
	@include background-color-hover-gradient($primary-color);
	margin-right: 1em;
	.handle {
		background-image: url(../images/icons/icon-draggable.svg);
		background-size: cover;
		background-position: center;
		height: $icon-height;
		width: $icon-width;
		margin: 0;
		padding: 0;
		@include border-3d($button-border-color);
	}
}
.tabledrag-handle-hover .handle {
	background-position: center;
}

// Navbar icons
.icon-help,
.icon-settings, 
.icon-switch, 
.icon-messages,
.icon-friends,
.icon-notifications {
	@extend .icon;
	@include vr(
		$margin: 0.25 
	);
}

//.icon-close {
.icon-edit a {
	@extend .icon;
	@extend .icon-small;
	float: left;
	@include vr(
		$margin: 0 0.25 0.25 0.25
	);
}

// Inline icons
.icon-back,
.icon-add,
.icon-search,
.icon-close,
.icon-menu,
.icon-refresh {
	float: left;
	@extend .icon;
	@include background-color-hover-gradient($button-background-color);
	a {
		@include border-3d($button-border-color);
	}
	@include vr(
		$margin: 0 0.25 0.25 0
	);
}

// Match formating of navigation bar
.icon-help {
  @include vr(
    $margin: 0.25 0.5 0 0
  );
  border-bottom: solid 1px transparent;
}


.icon-edit a {
	//background-image: url(../images/icons/icon-edit.svg);
	@include background-color-image-hover-gradient($button-background-color, url(../images/icons/icon-edit.svg));
	@include border-3d($button-background-color);
}
.icon-add a {
	background-image: url(../images/icons/icon-add.svg);
}
.icon-help a {
	background-image: url(../images/icons/icon-question.svg);
}
.icon-search a {
	background-image: url(../images/icons/icon-search.svg);
}
.icon-menu a {
	background-image: url(../images/icons/icon-menu.svg);
}

.icon-settings a {
	background-image: url(../images/icons/icon-settings.svg);
}

.icon-switch a {
	background-image: url(../images/icons/icon-switch.svg);
}

.icon-messages a {
	background-image: url(../images/icons/icon-mail.svg);
}

.icon-friends a {
	background-image: url(../images/icons/icon-friends.svg);
}

.icon-notifications  a {
	background-image: url(../images/icons/icon-bell.svg);
}

.icon-refresh a { 
 	background-image: url(../images/icons/icon-refresh.svg);
}

.icon-close a {
	background-image: url(../images/icons/icon-close.svg);
}


.icon-close {
	@extend .icon-small;
	float: left;
	padding: 0;
	@include vr(
		$margin: 0.25
	);
}

.block--system-user-menu {
	ul, ul.menu {
		li, li.leaf {
			float: left;
		}
	}
}
