//
// typography.scss
// Greenman Webdesigns
//

//$footer-at-bottom: false;
$footer-at-bottom: true;

// Borders
$border: solid 1px $border-color;
$border-radius: 4px;
$button-border: $border;

// Animations
$base-duration: 150ms;
$base-timing: ease;
