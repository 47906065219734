// Contain full page height and place footer at bottom of page
/*
$footer-height: 1em;
html, body {
	height: 100%;
  min-height: 100%;
}
.l-page, #page-wrapper {
	position: absolute;
	width: 100%;
	min-height: 100%;
	// Set to size of footer
	padding-bottom: $footer-height;
}
#footer-wrapper {
  position: absolute;
  bottom: 0px;
	width: 100%;
}
*/
