html body.admin-menu {
  @include vr(
    $margin-top: 2 
  );
}

#admin-menu-wrapper, #admin-menu {
  @include vr($font-size: -2);
  li {
    @include vr($font-size: -2);
  }
}

#admin-menu .dropdown .admin-menu-toolbar-category > a, #admin-menu .dropdown .admin-menu-action > a {
		border-radius: $border-radius;
}
