#header-wrapper {
	background-image: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0)), url(../images/flowers.svg), url(../images/leaf-bg.jpg);
	background-size: auto 100%, cover, cover;
	background-repeat: no-repeat;
	background-position: right, center;
	text-shadow:-1px 1px 2px #000, 0 0 1px #000;
	font-weight: bold;
	.contextual-links {
		text-shadow:none;
	}
}

.l-branding {
	font-family: $serif;
}
