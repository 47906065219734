@mixin responsive-columns($columns: 2, $margin: 2%, $reset: $columns - 1) {
	width: (100% / $columns) - ($margin * 2);
	margin: $margin;
	// reset previous column declaration 
  &:nth-child(#{$reset}n+1) {
		clear: none;
	}
  &:nth-child(#{$columns}n+1) {
		clear: left;
	}
}
