/*
 * _module-views-breakpoints.scss
 * Greenman Webdesigns
 * Nice default settings for views
 */

.view-noticeboard { 
	.views-row {
		margin: 2%;
		float: left;
		@include media($narrow) {
			@include responsive-columns(2);
		}
		@include media($normal) {
			@include responsive-columns(3);
		}
		@include media($wide) {
			@include responsive-columns(4);
		}
		@include media($hd) {
			@include responsive-columns(5);
		}
	}
}
