//
// _vars-colours-lighttheme.scss
// Greenman Webdesigns 2017
// 


$primary-color: #dc7b1f;


// Colors links
$secondary-color: #ff8919;

$grey-color: #c1c1c1;

$darken: 10;
$lighten: 10;

$shade: rgba(0,0,0,0.2);
$tint: rgba(255,255,255,0.1);
$shadow: 0 0 5px rgba(0,0,0,0.2);
$opacity: 0.8;

// Font colors
$font-color: #080808;
$heading-color: #333333; 
$header-color: #fff;
$toolbar-color: $header-color;
$navigation-color: $header-color;

$footer-color: $header-color;
$author-color: $footer-color;

// Border colors
$navigation-border-color: $grey-color;
$border-color: $grey-color;

// Background colors
$main-background-color: #fff;
$content-background-color: $main-background-color;
$navigation-wrapper-background-color: $shade;
$input-background-color: #fff;

$icon-background-color: $grey-color;

// Common element colors
$image-background-color: transparent;
$blockquote-background-color: #f8f8f8;
$blockquote-border-color: $border-color;
//$mark-background-color: #ffcc80;
$mark-background-color: #fff798;
$mark-color: #e00000;

// Region colors
$titlebar-background-color: $shade;

// Tables
$table-background-color: #efefef;
$th-background-color: $secondary-color;
$oddrow-background-color: #f3f3f3; // white value - 5
$evenrow-background-color: darken($oddrow-background-color, $darken); // white value - 15

// Messages
$status-color: #234600;
$status-background-color: #f8fff0;
$status-border-color: #5BB35B;

//$warning-color: #816635;
$warning-color: #48391d;
//$warning-background-color: #FFFDF0;
$warning-background-color: #f8f4c4;
//$warning-border-color: #ed5;
$warning-border-color: #d4d095;

$error-color: #333;
$error-background-color: #fef5f1;
$error-border-color: #ed541d;
