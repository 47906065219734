//
// _rhythm.scss
// Greenman Webdesigns
//
// Defines vertical and horizontal rhythm/layout
//

//
// Uncomment to show rhythm grid
//
//$debug-vr: true;
@if $debug-vr == true {
	body {
		@include vr-grid($highlight: true);
	}
}

// Set base typography
html, body {
	height: 100%;
  @include vr( 
		$font-size: 0,
		$margin: 0,
		$padding: 0
	)
}

#page-wrapper,
.l-page,
#main-wrapper {
  min-height: 100vh; 
	@include clearfix;
}

.l-main {
	@include vr(
		$padding: 0.25 0.25 0.5 0.25
	);
	@include media($tiny) {
		@include vr(
			$padding: 0.5 0.5 1 0.5
		);
	}
	@include media($small) {
		@include vr(
			$padding: 1 1 2 1
		);
	}
	@include media($narrow) {
		@include vr(
			$padding: 1 0 2 0
		);
	}
	@include media($large) {
		@include vr(
		  $padding-top: 2,
	  	$padding-bottom: 3
		);
	}
}

.l-region--footer {
	@include vr(
		$padding: 1 
	);
}

h1 {
  @include vr( 
    $font-size: 3, 
    $margin: 0.25 0 1 0 
  );
}
h2, h3, h4, h5 {
  @include vr( 
    $font-size: 1, 
		$line-height: 1.25,
    $padding  : 0.25 0 0.5 0, 
    $margin   : 0.5 0 0.5 0
  );
}
h2 {
	@include vr(
		$font-size: 2,
		$line-height: 1.25 
	);
}

p {
  @include vr( 
    $padding: 0,
    $margin: 0.5 0 
  );
	&:first-child {
		margin-top: 0;
	}
}

table {
  @include vr( 
		$margin: 0 0 1 0
	);
}
caption, th, td {
	@include vr(
		$padding: 0.25 0.5
	);
}

// Add a dab of extra whitespace to the first and last table row elements
tr:first-child > * {
	padding-top: 0.5em;
}
caption,
tr:last-child > * {
	padding-bottom: 0.5em;
}

// Experiment, first row of data in table
//tr:nth-child(n0 + 1) > * {
//	background-color: red;
//	@include vr(
//		$padding: 0.5 0.5 0.25 
//	);
//}

// All elements on left of row
tr > *:first-child {
	border-left-width: 1px;
}
// All elements on right of row
tr > *:last-child {
	border-right-width: 1px;
}
// All elements in top row
tr:first-child > * {
	border-top-width: 1px;
}
// All elelemtns in bottom row
tr:last-child > * {
	border-bottom-width: 1px;
}

th.active img { // Icon: sort arrow
	margin-left: 0.5em;
}



//
// Lists
//
//.node__content {
//	@include vr(
//		$margin: 0 0 1 0
//	);
//	ol, ul {
//  	@include vr( 
//			$margin: 1 0 1 1
//		);
//	}
//}

ul.breadcrumb {
	@include vr(
		$margin: 0,
		$padding: 0 
	);
}


li {
	margin: 0;
	padding: 0;
}
.l-navigation {
	@include vr(
		$padding: 0 0 0 0.5
	);
}
.l-region--toolbar,
.l-region--sidebar-left,
.l-region--sidebar-right,
.l-region--navigation {
	ul, ul.menu {
		li, li.leaf {
			@include vr(
				$margin-right: 0.25,
				$offset: 1
			);
			a {
				@include vr(
					$padding: 0.25 0.5,
					$margin: 0.25 0
				);
			}
		}
	}
}
.l-main .block {
	@include vr(
		$margin-bottom: 1
	);
}
.l-content .block {
	@include vr(
		$margin-bottom: 2
	);
}

ul.tabs--primary {
	@include vr(
		$font-size: -1,
		$margin: 0 0 1 0,
		$offset-bottom: 2 
	);
	display: block;
	border-bottom-width: 2px;
	li:first-child {
		@include vr(
			$margin-left: 0.125
		);
	}
	li {
		margin: 0;
		@include vr(
			$margin-right: 0.125
		);
	}
}


// 
// Forms
//

form {
	margin-top: 0;
	margin-bottom: 0;
}

fieldset {
	width: 100%;
	margin: 0;
	@include vr(
		$margin: 0.5 0 1 0,
		$offset: 2
	);
}

.fieldset-wrapper {
	margin: 0;
	@include vr(
		$padding: 0.5
	);
}

legend {
	@include vr(
		$margin-left: 0.5,
		$padding: 0 0.25
	);
}

tr .form-item,
.form-item {
	padding: 0;
	@include vr(
		$margin: 0.25 0 0.5 0
	);
	@include clearfix;
	label {
		@include vr(
			$margin: 0.25 0
		);
	}
}


.form-checkboxes .form-item, .form-radios .form-item  {
	width: auto;
	input {
		width: 1.25em;
		@include vr(
			$margin: 0.25  
		);
	}
}

.form-textarea-wrapper textarea, 
textarea {
	@include vr(
		$height: 5
	)
}
.form-type-textarea {
	@include vr(
		$offset: 1
	);
}
.grippie, .resizable-textarea .grippie {
	width: 100%;
	@include vr(
		$height: 0.5,
		$margin-bottom: 0.5,
		$offset: 1
	);
}
.description, .form-item .description, .form-actions .description {
	@include vr(
		$padding: 0.125 0 0.375 0,
		$margin: 0 0 0.5 0,
		$font-size: -1,
		$line-height: 0.8
	);
}

textarea, form-textarea-wrapper textarea {
	@include vr(
		$padding: 0 0.125
	);
}


// All text based input fields

/*
input, select, #{$all-buttons}, .button, // Correct vertical rhythm reset settings
input:not([type=radio]):not([type=checkbox]), .chosen-container-single .chosen-single:not([type=radio]):not([type=checkbox]), form#privatemsg-list div.container-inline select:not([type=radio]):not([type=checkbox]), option, select:not([multiple]) {
*/

input {
	@include vr(
  	$line-height: 1.25,
  	$height: 1.25,
		$padding: 0 0.125,
		$margin-right: 0.125
	);
	&:focus {
		border-width: 2px;
	}
}


.button, #{$all-buttons}, input[type=submit] {
	@include vr(
		$font-size: -1,
		$line-height: 1,
		$margin: 0.25 0.5 0.25 0 
	);
	padding: 0 1.5em;
	width: auto;
}

button,
input:not([type=radio]):not([type=checkbox]),
option,
select:not([multiple]) {
   @include vr(
		$height: 1.25
	);
}

option {
	@include vr(
		$height: 1
	)
}


// Radios and checkboxes
input[type="radio"], input[type="checkbox"] {
	margin: 0;
	@include vr(
		$padding-bottom: 0.25,
		$margin-right: 0.125
	)
}

.form-checkboxes .form-item,
.form-radios .form-item {
	margin: 0;
}

.form-checkboxes .form-item:last-child,
.form-radios .form-item:last-child {
	@include vr(
		$margin-bottom: 1
	);	
}

input[type=file] {
	padding: 0;
	@include vr(
		$padding-left: 0.25,
		$line-height: 1
	);
}

//
// Login forms
//

.login-forgot {
	@include vr(
		$margin-bottom: 2
	);
}
.password-strength {
	@include vr(
		$margin-bottom: 2
	);
	.password-strength-title {
		@include vr(
			$margin-bottom: 1
		);
	}
}

.node--page {
	.field--name-field-images .field__item {
		@include vr(
			$margin-bottom: 1
		);
	}
}

.messages {
	max-width: $max-width;
	@include vr(
		$margin: 0.5 auto,
		$padding: 0.25 0.5 0.5 1.75,
		$offset: 2
	);
	&:last-child {
		margin-bottom: 1em;
	}
}

blockquote {
	@include vr(
		$margin: 1 4% 1,
		$padding: 1,
		$font-size: 1,
		$line-height: 1.5
	);
//	@include media($narrow) {
//		float: left;
//		width: 50%;
//		margin-left: -0.25em;
//	}
}

address {
	@include vr(
		$padding-bottom: 1
	);
}


dd {
	@include vr(
		$margin-bottom: 0.5
	);
}

pre {
	margin: 0;
	@include vr (
		$padding: 0 0 0.5 0
	);
}


.superscript,
sup {
  @include vr(
    $font-size: -1,
    $padding: 0.125 0 0 0
  );
}

//iframe,
//video {
//  width: 100% !important;
//  height: auto !important;
//}

// Inline fields
.inline {
	.field__label {
		float: left;
		width: 30%;
		text-align: right;
	}
	.field__items {
		margin-left: 30%;
	}
	.field__item {
		margin-left: 0.5em;
	}
}
.inline-fieldgroup {
	display: inline-block;
}

.tabledrag-toggle-weight-wrapper {
	@include vr(
		$font-size: -1
	);
}


iframe {
	width: 100%;
}

//.icon-text {
//	@include vr(
//		$font-size: -1,
//		$lineheight: 0.8
//	);
//}
