.field-slideshow-controls {
	text-align: center;

	a.pref,
	a.play,
	a.pause,
	a.next {
		//@extend .icon;
		//background: url(../images/icons/icon-next) no-repeat 0px 0px;
		//background-size: cover;
	}
}

.field-slideshow {
	margin: 0;
}

.field-slideshow-caption {
	@include vr(
		$font-size: -1,
		$line-height: 0.8,
		$margin: 0 0 0.5 0
	);
	text-align: center;
}
