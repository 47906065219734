$primary-color: #659916;
$secondary-color: #dc7b1f;

// Region backgrounds
$tint-background-color: rgba(0,0,0,0.4);
$body-background-color: #eee;
$toolbar-background-color: #2b5c1c;
$header-background-color: #609E4D;
$navigation-wrapper-background-color: $tint-background-color;
$page-background-color: #FFFDF2;
$main-background-color: #fff;
$sidebar-first-background-color: transparent;
$sidebar-second-background-color: transparent;
$footer-background-color: #4ca830;
$author-background-color: $footer-background-color;

// Font colours
$font-color: #5B5A5A;
$heading-color: #424242; 
//$link-color: #348031;
$link-color: #26a824;

$border-color: #b1aa96;

// Region font colors
$toolbar-color: #FFFAE4;
$header-color: #fff;
$navigation-color: #FFFAE4;

$footer-color: #fff;

$button-border-color: #bfbbbb;
