.views-field-field-video-file {
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 51%;

	.video-js,
	video {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
	}
}
