/*
 * _views-filters.scss 
 * Greenman Webdesigns
*/

.view-filters {
	form {
		margin: 0;
	}
	//.fieldset {
	//}
	.form-type-bef-checkbox {
		@include media($normal) {
			text-align: center;
			@include responsive-columns(4);
		}
  }
}


.views-exposed-form {
	@include vr(
		$margin: 0.5 0,
		$padding: 0.5
	);
	.views-exposed-widget {
		width: 100%;
		max-width: 100%;
		margin: 0;
		@include vr(
			$margin-bottom: 0.5 
		);
		@include media ($small) {
			@include responsive-columns(2);
		}
		@include media ($narrow) {
			@include responsive-columns(3);
		}
		@include media ($normal) {
			@include responsive-columns(4);
		}
		@include media ($large) {
			@include responsive-columns(6, 2%, 4);
		}
		@include media ($wide) {
			@include responsive-columns(8, 2%, 6);
		}
		select, input:not([type=submit]) {
			width: 100%;
		}
	}
	.views-reset-button,
	.views-submit-button {
		width: auto;
		float: left;
	}
	.form-submit {
		@include vr(
			$margin-top: 1
		);
	}
}

// Override views.css defaults
.views-exposed-widgets {
	margin: 0;
}
.views-exposed-form {
	.views-exposed-widget {
		padding: 0;
		.form-submit {
			@include vr(
				$margin-top: 1
			);
		}
	}
}
