.icon {
	z-index: 200; // Contextual links are 999
}

.js .modal-menu,
.js .l-filters {
 	z-index: 20;
}

.js .modal-menu.is-visible {
	z-index: 21;
}

#modal-background.is-visible {
	z-index: 15;
}
