$html-background-color: inherit;
$body-background-color: inherit;

$page-background-color: inherit;
$toolbar-background-color: inherit;
$header-background-color: inherit;
$navigation-background-color: transparent;

$main-background-color: inherit;

$sidebar-first-background-color: inherit;
$sidebar-second-background-color: inherit;

$footer-background-color: inherit;

$menu-background-color: inherit;
$image-background-color: inherit;
$icon-background-color: inherit;

$button-background-color: transparent;
$button-border-color: transparent;

$input-border-color: transparent;

$link-color: inherit;

