
div.toboggan-container {
  text-align: center;
}

div.toboggan-login-link-container {
  text-align: left;
}

div.toboggan-login-link-container a {
  text-decoration: none;
}

div.toboggan-block-message {
  text-align: left;
}

div.user-login-block {
  text-align: left;
}

div.user-login-block a {
  text-align: left;
}

/* Unified login */

.toboggan-unified #login-message {
  text-align: center;
	@extend .messages;
	@extend .warning;
}

.toboggan-unified, .login {
	@include vr(
		$font-size: 0
	);
  #login-message {
		@include vr(
			$font-size: 1
		);
  }
	#login-links a.active {
		font-weight: normal;
	}
	#login-links a.lt-active {
		font-weight: bold;
	}
	#login-links a,
	#login-links a:hover,
	#login-links a.lt-active {
  	text-align: center;
		display: inline-block;
		@include vr(
			$font-size: 1
		);
		color: $button-color !important;
		 background-color: $button-background-color !important;
		border: $border !important;
		@include vr(
			$margin-bottom: 0.5
		);
	}
}

.toboggan-unified #login-links a {
	background-color: transparent;
	color: $link-color;
}

.toboggan-unified #login-links a:hover {
//  background-color: #DDD;
	background-color: transparent;
	color: $link-color-hover;
}

.toboggan-unified #login-links a.lt-active,
.toboggan-unified #login-links a.lt-active:hover {
  //background-color: #FFF19A;
	background-color: transparent;
  //border: 2px #333 solid;
//	border: none;
	color: $link-color;
}

.toboggan-unified.login #register-form {
  display: none;
}

.toboggan-unified.register #login-form {
  display: none;
}

.block--user-login {
	margin: 0;
	a {
		display: block;
		padding: ($small-spacing / 2) $base-spacing;
    transition: background-color $base-duration $base-timing;
		&:hover {
      background-color: $navigation-background-color-hover;
    }
	}
	border: 0;
	line-height: $vr-line-height;
	float: right;
	.user-login-block {
		border: $border;
		padding-top: $small-spacing;
		padding-left: $base-spacing;
	}
}
