$shade-amount: 30;
$header-contrast: 0.3;
@mixin color-header($color, $header-contrast: $header-contrast) {
  $color2: adjust-hue($color, -8);
  $shade: shade($color2, $shade-amount);
	text-shadow: -1px 1px rgba(0,0,0,0.1);

  // Transparent colors
  $color-alpha: transparentize($color, 0.3);
  $shade-alpha: transparentize($shade, 0.2);

  background-image: linear-gradient(rgba(0,0,0,$header-contrast), rgba(0,0,0,$header-contrast/2)), linear-gradient($shade-alpha, $color-alpha);
  &:focus,
  &:hover {
    $color: lighten($color, 10);
    $color-alpha: transparentize($color, 0.3);
    $shade: lighten($shade, 10);
    $shade-alpha: transparentize($shade, 0.2);
    background-image: linear-gradient(rgba(0,0,0, $header-contrast), rgba(0,0,0,$header-contrast/2)), linear-gradient($shade-alpha, $color-alpha);
  }
}


@mixin color-header-nohover($color) {
  $color2: adjust-hue($color, -8);
  $shade: shade($color2, $shade-amount);

  // Transparent colors
  $color-alpha: transparentize($color, 0.3);
  $shade-alpha: transparentize($shade, 0.2);
  background-image: linear-gradient(rgba(0,0,0,($header-contrast)), rgba(0,0,0,$header-contrast/2)), linear-gradient($shade-alpha, $color-alpha);
}
