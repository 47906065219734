//
// _node-base.scss
// Greenman Webdesgns
//

.node {
	.field--image img, img.media-image {
		max-width: 100%;
	}
}
