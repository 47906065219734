@charset "UTF-8";
@font-face {
  font-family: "freesans";
  src: url("../fonts/freesans/freesans-webfont.woff2") format("woff2"), url("../fonts/freesans/freesans-webfont.woff") format("woff"), url("../fonts/freesans/freesans-webfont.ttf") format("truetype"); }

@font-face {
  font-family: "charter";
  src: url("../fonts/charter/charter_regular-webfont.woff2") format("woff2"), url("../fonts/charter/charter_regular-webfont.woff") format("woff"), url("../fonts/charter/charter_regular-webfont.ttf") format("truetype"); }

@font-face {
  font-family: "charter-bold";
  src: url("../fonts/charter/charter_bold-webfont.woff2") format("woff2"), url("../fonts/charter/charter_bold-webfont.woff") format("woff"), url("../fonts/charter/charter_bold-webfont.ttf") format("truetype"); }

.serif {
  font-family: "charter", "Georgia", "Times", "Times New Roman", serif; }

.sans-serif {
  font-family: "freesans", "Helvetica Neue", "Helvetica", "Arial", sans-serif; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, .tabs--secondary a, abbr, acronym, address,
big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center,
dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article,
aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote::before, blockquote::after {
  content: '';
  content: none; }

q::before, q::after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* Vertical Rhythm Reset: Base */
*,
*:before,
*:after {
  box-sizing: inherit;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  font: inherit;
  vertical-align: bottom; }

html {
  box-sizing: border-box;
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

body {
  margin: 0;
  background: #fff;
  color: #000; }

button, .form-actions a,
fieldset,
input:not([type="radio"]):not([type="checkbox"]),
select,
textarea {
  border-width: 1px;
  border-style: solid;
  border-color: #808080; }

iframe {
  border: 0; }

code,
kbd,
pre,
samp {
  font-family: monospace;
  font-size: 0.875em; }

small,
sub,
sup {
  font-size: 0.875em; }

sub,
sup {
  line-height: 0; }

input[type="radio"],
input[type="checkbox"] {
  margin-bottom: 0.325em; }

hr {
  position: relative;
  border: 0; }
  hr:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background: #808080; }

sub,
sup {
  position: relative; }

sub {
  bottom: -0.2em; }

sup {
  top: -0.2em; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

template,
[hidden] {
  display: none; }

a, .tabs--secondary a {
  background-color: transparent; }

a:active,
a:hover {
  outline-width: 0; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

b,
strong {
  font-weight: bolder; }

dfn {
  font-style: italic; }

mark {
  background-color: #ff0;
  color: #000; }

img {
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

button, .form-actions a,
input,
select {
  overflow: visible; }

button, .form-actions a,
select {
  text-transform: none; }

button, .form-actions a,
[type="button"],
[type="reset"],
[type="submit"] {
  cursor: pointer; }

[disabled] {
  cursor: default; }

button, .form-actions a,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner, .form-actions a::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

button:-moz-focusring, .form-actions a:-moz-focusring,
input:-moz-focusring {
  outline: 1px dotted ButtonText; }

legend {
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/* Vertical Rhythm Reset: Alignment */
:root {
  font-size: 16px; }

*,
*:before,
*:after {
  line-height: 1.31251rem; }

button, .form-actions a,
input:not([type="radio"]):not([type="checkbox"]),
option,
select:not([multiple]) {
  height: 1.31251rem; }

audio {
  height: 2.62502rem; }

hr {
  height: 2.62502rem; }

select[multiple],
textarea {
  height: 5.25004rem; }

iframe,
video {
  height: 10.50008rem; }

h1 {
  margin-top: 0px;
  margin-bottom: 1.31251rem; }

blockquote,
dl,
form,
h2,
h3,
h4,
h5,
h6,
ol,
p,
pre,
table,
ul {
  margin-top: 1.31251rem;
  margin-bottom: 1.31251rem; }

fieldset {
  display: inline-block;
  margin: 1.31251rem 0 calc((1.31251rem) - 1px);
  width: 100%; }

h1 {
  font-size: 2.44141rem;
  line-height: 2.62501rem; }

h2 {
  font-size: 1.95312rem;
  line-height: 2.62501rem; }

h3 {
  font-size: 1.5625rem;
  line-height: 2.62501rem; }

h4 {
  font-size: 1.25rem;
  line-height: 1.31251rem; }

h5 {
  font-size: 1rem;
  line-height: 1.31251rem; }

h6 {
  font-size: 0.8rem;
  line-height: 1.31251rem; }

@media (min-width: 60em) {
  /* Vertical Rhythm Reset: Breakpoint Alignment */
  :root {
    font-size: 18px; }
  *,
  *:before,
  *:after {
    line-height: 1.3889rem; }
  button, .form-actions a,
  input:not([type="radio"]):not([type="checkbox"]),
  option,
  select:not([multiple]) {
    height: 1.3889rem; }
  audio {
    height: 2.7778rem; }
  hr {
    height: 2.7778rem; }
  select[multiple],
  textarea {
    height: 5.5556rem; }
  iframe,
  video {
    height: 11.11119rem; }
  h1 {
    margin-top: 0px;
    margin-bottom: 1.3889rem; }
  blockquote,
  dl,
  form,
  h2,
  h3,
  h4,
  h5,
  h6,
  ol,
  p,
  pre,
  table,
  ul {
    margin-top: 1.3889rem;
    margin-bottom: 1.3889rem; }
  fieldset {
    display: inline-block;
    margin: 1.3889rem 0 calc((1.3889rem) - 1px);
    width: 100%; }
  h1 {
    font-size: 2.44141rem;
    line-height: 2.77779rem; }
  h2 {
    font-size: 1.95312rem;
    line-height: 2.77779rem; }
  h3 {
    font-size: 1.5625rem;
    line-height: 2.77779rem; }
  h4 {
    font-size: 1.25rem;
    line-height: 1.3889rem; }
  h5 {
    font-size: 1rem;
    line-height: 1.3889rem; }
  h6 {
    font-size: 0.8rem;
    line-height: 1.3889rem; } }

@media (min-width: 90em) {
  /* Vertical Rhythm Reset: Breakpoint Alignment */
  :root {
    font-size: 20px; }
  *,
  *:before,
  *:after {
    line-height: 1.30001rem; }
  button, .form-actions a,
  input:not([type="radio"]):not([type="checkbox"]),
  option,
  select:not([multiple]) {
    height: 1.30001rem; }
  audio {
    height: 2.60002rem; }
  hr {
    height: 2.60002rem; }
  select[multiple],
  textarea {
    height: 5.20004rem; }
  iframe,
  video {
    height: 10.40008rem; }
  h1 {
    margin-top: 0px;
    margin-bottom: 1.30001rem; }
  blockquote,
  dl,
  form,
  h2,
  h3,
  h4,
  h5,
  h6,
  ol,
  p,
  pre,
  table,
  ul {
    margin-top: 1.30001rem;
    margin-bottom: 1.30001rem; }
  fieldset {
    display: inline-block;
    margin: 1.30001rem 0 calc((1.30001rem) - 1px);
    width: 100%; }
  h1 {
    font-size: 3.15733rem;
    line-height: 3.90001rem; }
  h2 {
    font-size: 2.36859rem;
    line-height: 2.60001rem; }
  h3 {
    font-size: 1.77689rem;
    line-height: 2.60001rem; }
  h4 {
    font-size: 1.333rem;
    line-height: 2.60001rem; }
  h5 {
    font-size: 1rem;
    line-height: 1.30001rem; }
  h6 {
    font-size: 0.75019rem;
    line-height: 1.30001rem; } }

@media only screen and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  /* Vertical Rhythm Reset: Fix for mobile select[multiple] elements */
  select[multiple],
  select[multiple][size] {
    height: 1.31251rem; } }

@keyframes (fadein) {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.l-main {
  animation: fadein 0.8s 1; }

html {
  background-color: inherit; }

body {
  color: #5B5A5A;
  background-color: #eee; }

.l-content img {
  background-color: transparent; }

mark {
  color: #e00000;
  background-color: #fff798; }

#toolbar-wrapper {
  background-color: #2b5c1c;
  color: #FFFAE4; }

#page-wrapper {
  background-color: #FFFDF2; }

.l-main {
  background-color: #fff; }

.views-row {
  background-color: #fff; }

#header-wrapper {
  background-color: #609E4D;
  color: #fff; }

.l-navigation {
  background-color: transparent;
  color: #FFFAE4; }

#navigation-wrapper {
  background-color: rgba(0, 0, 0, 0.4); }

#footer-wrapper {
  color: #fff;
  background-color: #4ca830; }

.l-region--sidebar-first {
  background-color: transparent; }

.l-region--sidebar-second {
  background-color: transparent; }

.messages, .toboggan-unified #login-message, .error {
  background-repeat: no-repeat;
  background-position: 0.25em 0.25em; }

.messages, .toboggan-unified #login-message, .status, .messages--status {
  color: #234600;
  background-color: #f8fff0;
  background-image: url("../images/misc/message-24-ok.png");
  border-color: #6bbb6b #5BB35B #52a152 #56aa56;
  border-width: 1px;
  border-style: solid;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }

.warning, .toboggan-unified #login-message {
  color: #48391d; }

.messages--warning, tr.warning, .toboggan-unified tr#login-message {
  color: #48391d;
  background-color: #f8f4c4;
  background-image: url("../images/misc/message-24-warning.png");
  border-color: #d8d5a0 #d4d095 #bfbb86 #c9c68e;
  border-width: 1px;
  border-style: solid;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }

.messages--error, tr.error {
  color: #333;
  background-color: #fef5f1;
  background-image: url("../images/misc/message-24-error.png");
  border-color: #ef6534 #ed541d #d54c1a #e1501c;
  border-width: 1px;
  border-style: solid;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }

.error {
  color: #333;
  background-color: #fef5f1; }

a, .tabs--secondary a {
  color: #dc7b1f;
  text-decoration: none; }
  a:visited {
    color: #c66f1c; }
  a:active, a:focus,
  a:hover {
    color: #e08835; }

a.active, .tabs--secondary a.active {
  color: #e08835; }

.l-header, .l-region--header {
  color: #fff; }
  .l-header h1, .l-header h2, .l-header h3, .l-region--header h1, .l-region--header h2, .l-region--header h3 {
    color: #fff; }
  .l-header a, .l-region--header a {
    color: #fff; }
    .l-header a:hover, .l-region--header a:hover {
      color: white; }
    .l-header a:active, .l-header a .active, .l-region--header a:active, .l-region--header a .active {
      color: white; }

.button, button, .form-actions a, [type='button'], [type='reset'], [type='submit'] {
  cursor: pointer;
  appearance: none;
  border: 0;
  color: #FFFAE4;
  background-color: transparent;
  background-image: linear-gradient(rgba(0, 0, 0, 0), transparent, rgba(26, 26, 26, 0)); }
  .button:hover,
  button:hover,
  .form-actions a:hover, [type='button']:hover, [type='reset']:hover, [type='submit']:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(26, 26, 26, 0), rgba(51, 51, 51, 0)); }
  .button:hover, .button:visited,
  button:hover,
  .form-actions a:hover,
  button:visited,
  .form-actions a:visited, [type='button']:hover, [type='button']:visited, [type='reset']:hover, [type='reset']:visited, [type='submit']:hover, [type='submit']:visited {
    color: #FFFAE4;
    text-decoration: none; }
  .button:focus,
  button:focus,
  .form-actions a:focus, [type='button']:focus, [type='reset']:focus, [type='submit']:focus {
    border-width: 2px; }
  .button:active,
  button:active,
  .form-actions a:active, [type='button']:active, [type='reset']:active, [type='submit']:active {
    background-color: rgba(26, 26, 26, 0); }
  .button:disabled,
  button:disabled,
  .form-actions a:disabled, [type='button']:disabled, [type='reset']:disabled, [type='submit']:disabled {
    cursor: not-allowed;
    opacity: 0.5; }
    .button:disabled:hover,
    button:disabled:hover,
    .form-actions a:disabled:hover, [type='button']:disabled:hover, [type='reset']:disabled:hover, [type='submit']:disabled:hover {
      background-color: #c1c1c1;
      background-image: linear-gradient(#a8a8a8, #c1c1c1, #dbdbdb); }

.l-navigation .icon:hover, .l-navigation .icon-small:hover, .l-navigation .icon-edit a:hover, .icon-edit .l-navigation a:hover, .l-navigation .icon-close:hover, .l-navigation .tabledrag-handle:hover, .l-navigation .icon-help:hover,
.l-navigation .icon-settings:hover,
.l-navigation .icon-switch:hover,
.l-navigation .icon-messages:hover,
.l-navigation .icon-friends:hover,
.l-navigation .icon-notifications:hover, .l-navigation .icon-back:hover,
.l-navigation .icon-add:hover,
.l-navigation .icon-search:hover,
.l-navigation .icon-menu:hover,
.l-navigation .icon-refresh:hover {
  background-color: rgba(255, 255, 255, 0.1); }

.l-region--toolbar a,
.l-region--navigation a {
  color: #FFFAE4;
  text-decoration: none;
  border: solid 1px transparent; }
  .l-region--toolbar a:hover,
  .l-region--navigation a:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.19) rgba(255, 255, 255, 0.1) rgba(82, 82, 82, 0.19) rgba(128, 128, 128, 0.145);
    border-width: 1px;
    border-style: solid;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }

.l-region--toolbar a.active, .l-region--toolbar a:active,
.l-region--navigation a.active,
.l-region--navigation a:active {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.19) rgba(255, 255, 255, 0.1) rgba(82, 82, 82, 0.19) rgba(128, 128, 128, 0.145);
  border-width: 1px;
  border-style: solid;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }

.ui-tabs-nav,
ul.tabs--primary,
.vertical-tabs ul.vertical-tabs-list {
  color: #FFFAE4;
  border-color: #b1aa96;
  background-image: linear-gradient(transparent 50%, rgba(0, 0, 0, 0.05)); }
  .ui-tabs-nav li, .ui-tabs-nav li.selected, .ui-tabs-nav li.ui-state-default,
  ul.tabs--primary li,
  ul.tabs--primary li.selected,
  ul.tabs--primary li.ui-state-default,
  .vertical-tabs ul.vertical-tabs-list li,
  .vertical-tabs ul.vertical-tabs-list li.selected,
  .vertical-tabs ul.vertical-tabs-list li.ui-state-default {
    color: #FFFAE4; }
    .ui-tabs-nav li a, .ui-tabs-nav li a:visited, .ui-tabs-nav li.selected a, .ui-tabs-nav li.selected a:visited, .ui-tabs-nav li.ui-state-default a, .ui-tabs-nav li.ui-state-default a:visited,
    ul.tabs--primary li a,
    ul.tabs--primary li a:visited,
    ul.tabs--primary li.selected a,
    ul.tabs--primary li.selected a:visited,
    ul.tabs--primary li.ui-state-default a,
    ul.tabs--primary li.ui-state-default a:visited,
    .vertical-tabs ul.vertical-tabs-list li a,
    .vertical-tabs ul.vertical-tabs-list li a:visited,
    .vertical-tabs ul.vertical-tabs-list li.selected a,
    .vertical-tabs ul.vertical-tabs-list li.selected a:visited,
    .vertical-tabs ul.vertical-tabs-list li.ui-state-default a,
    .vertical-tabs ul.vertical-tabs-list li.ui-state-default a:visited {
      color: #FFFAE4;
      border-color: #c5c2c2 #bfbbbb #aca8a8 #b5b2b2;
      border-width: 1px;
      border-style: solid;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      background-color: #5b8a14;
      background-image: linear-gradient(#3e5d0e, #5b8a14, #78b71a); }
      .ui-tabs-nav li a:hover, .ui-tabs-nav li a:visited:hover, .ui-tabs-nav li.selected a:hover, .ui-tabs-nav li.selected a:visited:hover, .ui-tabs-nav li.ui-state-default a:hover, .ui-tabs-nav li.ui-state-default a:visited:hover,
      ul.tabs--primary li a:hover,
      ul.tabs--primary li a:visited:hover,
      ul.tabs--primary li.selected a:hover,
      ul.tabs--primary li.selected a:visited:hover,
      ul.tabs--primary li.ui-state-default a:hover,
      ul.tabs--primary li.ui-state-default a:visited:hover,
      .vertical-tabs ul.vertical-tabs-list li a:hover,
      .vertical-tabs ul.vertical-tabs-list li a:visited:hover,
      .vertical-tabs ul.vertical-tabs-list li.selected a:hover,
      .vertical-tabs ul.vertical-tabs-list li.selected a:visited:hover,
      .vertical-tabs ul.vertical-tabs-list li.ui-state-default a:hover,
      .vertical-tabs ul.vertical-tabs-list li.ui-state-default a:visited:hover {
        background-image: linear-gradient(#5b8a14, #78b71a, #95df25); }
      .ui-tabs-nav li a strong, .ui-tabs-nav li a:visited strong, .ui-tabs-nav li.selected a strong, .ui-tabs-nav li.selected a:visited strong, .ui-tabs-nav li.ui-state-default a strong, .ui-tabs-nav li.ui-state-default a:visited strong,
      ul.tabs--primary li a strong,
      ul.tabs--primary li a:visited strong,
      ul.tabs--primary li.selected a strong,
      ul.tabs--primary li.selected a:visited strong,
      ul.tabs--primary li.ui-state-default a strong,
      ul.tabs--primary li.ui-state-default a:visited strong,
      .vertical-tabs ul.vertical-tabs-list li a strong,
      .vertical-tabs ul.vertical-tabs-list li a:visited strong,
      .vertical-tabs ul.vertical-tabs-list li.selected a strong,
      .vertical-tabs ul.vertical-tabs-list li.selected a:visited strong,
      .vertical-tabs ul.vertical-tabs-list li.ui-state-default a strong,
      .vertical-tabs ul.vertical-tabs-list li.ui-state-default a:visited strong {
        color: #FFFAE4; }
    .ui-tabs-nav li a.active, .ui-tabs-nav li a:active, .ui-tabs-nav li.selected a.active, .ui-tabs-nav li.selected a:active, .ui-tabs-nav li.ui-state-default a.active, .ui-tabs-nav li.ui-state-default a:active,
    ul.tabs--primary li a.active,
    ul.tabs--primary li a:active,
    ul.tabs--primary li.selected a.active,
    ul.tabs--primary li.selected a:active,
    ul.tabs--primary li.ui-state-default a.active,
    ul.tabs--primary li.ui-state-default a:active,
    .vertical-tabs ul.vertical-tabs-list li a.active,
    .vertical-tabs ul.vertical-tabs-list li a:active,
    .vertical-tabs ul.vertical-tabs-list li.selected a.active,
    .vertical-tabs ul.vertical-tabs-list li.selected a:active,
    .vertical-tabs ul.vertical-tabs-list li.ui-state-default a.active,
    .vertical-tabs ul.vertical-tabs-list li.ui-state-default a:active {
      color: #fff;
      background-color: #74a32d;
      background-image: linear-gradient(#587b22, #74a32d, #90c93a); }
      .ui-tabs-nav li a.active:hover, .ui-tabs-nav li a:active:hover, .ui-tabs-nav li.selected a.active:hover, .ui-tabs-nav li.selected a:active:hover, .ui-tabs-nav li.ui-state-default a.active:hover, .ui-tabs-nav li.ui-state-default a:active:hover,
      ul.tabs--primary li a.active:hover,
      ul.tabs--primary li a:active:hover,
      ul.tabs--primary li.selected a.active:hover,
      ul.tabs--primary li.selected a:active:hover,
      ul.tabs--primary li.ui-state-default a.active:hover,
      ul.tabs--primary li.ui-state-default a:active:hover,
      .vertical-tabs ul.vertical-tabs-list li a.active:hover,
      .vertical-tabs ul.vertical-tabs-list li a:active:hover,
      .vertical-tabs ul.vertical-tabs-list li.selected a.active:hover,
      .vertical-tabs ul.vertical-tabs-list li.selected a:active:hover,
      .vertical-tabs ul.vertical-tabs-list li.ui-state-default a.active:hover,
      .vertical-tabs ul.vertical-tabs-list li.ui-state-default a:active:hover {
        background-image: linear-gradient(#74a32d, #90c93a, #a7d462); }

.tabs--secondary a {
  color: #FFFAE4;
  background-color: #5b8a14;
  background-image: linear-gradient(#3e5d0e, #5b8a14, #78b71a); }
  .tabs--secondary a:hover {
    background-image: linear-gradient(#5b8a14, #78b71a, #95df25); }

.tabs--secondary a.active {
  color: #FFFAE4;
  background-color: #74a32d;
  background-image: linear-gradient(#587b22, #74a32d, #90c93a); }
  .tabs--secondary a.active:hover {
    background-image: linear-gradient(#74a32d, #90c93a, #a7d462); }

.tabs-secondary a:hover, .tabs-secondary a:focus {
  color: #FFFAE4;
  background-color: #74a32d;
  background-image: linear-gradient(#587b22, #74a32d, #90c93a); }
  .tabs-secondary a:hover:hover, .tabs-secondary a:focus:hover {
    background-image: linear-gradient(#74a32d, #90c93a, #a7d462); }

table {
  border-collapse: separate;
  border-width: 1px;
  border-style: solid;
  border-color: #74a32d #659916 #5b8a14 #609115;
  border-width: 1px;
  border-style: solid;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }

th, td {
  border-style: solid;
  border-color: transparent; }

th {
  color: #FFFAE4;
  border-color: #74a32d #659916 #5b8a14 #609115;
  border-width: 1px;
  border-style: solid;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  background-color: #659916; }
  th a, th .tabs--secondary a, .tabs--secondary th a, th a.active, th .tabs--secondary a.active, .tabs--secondary th a.active {
    color: #FFFAE4; }

th.active {
  background-color: #74a32d; }

tr:nth-child(odd),
tr.odd {
  background-color: #f3f3f3; }
  tr:nth-child(odd) td.active,
  tr.odd td.active {
    color: #FFFAE4;
    background-color: rgba(0, 0, 0, 0.2); }

tr:nth-child(even),
tr.even {
  background-color: #dadada; }
  tr:nth-child(even) th,
  tr.even th {
    background-color: #659916; }
  tr:nth-child(even) td.active,
  tr.even td.active {
    color: #FFFAE4;
    background-color: rgba(255, 255, 255, 0.1); }

fieldset,
input:not([type="radio"]):not([type="checkbox"]),
select,
textarea,
fieldset {
  color: #5B5A5A;
  background-color: #fff;
  border-color: #c5c2c2 #bfbbbb #aca8a8 #b5b2b2;
  border-width: 1px;
  border-style: solid;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }

button, .form-actions a, [type='button'], [type='reset'], [type='submit'], input:not([type="radio"]):not([type="checkbox"]), input[type="submit"] {
  border-color: #c5c2c2 #bfbbbb #aca8a8 #b5b2b2;
  border-width: 1px;
  border-style: solid;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }

.l-main h1, .l-main h2, .l-main h3, .l-main h4, .l-main h5 {
  color: #424242; }

.l-main h1 a, .l-main h2 a, .l-main h3 a {
  color: #424242; }
  .l-main h1 a:visited, .l-main h2 a:visited, .l-main h3 a:visited {
    color: #424242; }
  .l-main h1 a:hover, .l-main h2 a:hover, .l-main h3 a:hover {
    color: #757575; }
  .l-main h1 a:active, .l-main h1 a a.active, .l-main h2 a:active, .l-main h2 a a.active, .l-main h3 a:active, .l-main h3 a a.active {
    color: #424242; }

.node-unpublished {
  background-color: #fef5f1; }

.resizable-textarea .grippie {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: #c5c2c2 #bfbbbb #aca8a8 #b5b2b2;
  border-width: 1px;
  border-style: solid;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }

blockquote {
  color: #424242;
  background-color: #f8f8f8;
  border-color: #c1c1c1; }

html {
  -webkit-font-smoothing: antialiased; }

body {
  font-family: "freesans", "Helvetica Neue", "Helvetica", "Arial", sans-serif; }

.heading,
h1, h2, h3, h4, h5 {
  font-family: "charter-bold", "charter", "Georgia", "Times", "Times New Roman", serif;
  text-rendering: optimizeLegibility; }

.l-header, .l-region--navigation {
  text-rendering: optimizeLegibility; }

p {
  hyphens: auto; }

strong {
  font-weight: bold; }

em {
  font-style: italic; }

.l-content {
  font-family: "freesans", "Helvetica Neue", "Helvetica", "Arial", sans-serif; }

a.active, .tabs--secondary a.active {
  font-weight: bold; }

.l-region--navigation ul li, .l-region--navigation ul li.leaf, .l-region--navigation ul.menu li, .l-region--navigation ul.menu li.leaf {
  font-weight: bold; }

.fieldset-legend {
  font-weight: bold; }

.button, button, .form-actions a, [type='button'], [type='reset'], [type='submit'] {
  font-weight: bold; }

blockquote {
  font-family: "Lucida Grande", "Lucida Sans Unicode", "Geneva", "Verdana", sans-serif; }

.form-actions {
  font-family: "freesans", "Helvetica Neue", "Helvetica", "Arial", sans-serif; }

/*
$footer-height: 1em;
html, body {
	height: 100%;
  min-height: 100%;
}
.l-page, #page-wrapper {
	position: absolute;
	width: 100%;
	min-height: 100%;
	// Set to size of footer
	padding-bottom: $footer-height;
}
#footer-wrapper {
  position: absolute;
  bottom: 0px;
	width: 100%;
}
*/
.icon, .icon-small, .icon-edit a, .icon-close, .tabledrag-handle, .icon-help,
.icon-settings,
.icon-switch,
.icon-messages,
.icon-friends,
.icon-notifications, .icon-back,
.icon-add,
.icon-search,
.icon-menu,
.icon-refresh {
  height: 2em;
  width: 2em;
  padding: 0;
  color: transparent;
  border-radius: 4px;
  text-shadow: none;
  cursor: pointer;
  overflow: hidden;
  background-size: cover; }
  .icon a, .icon-small a, .icon-edit a a, .icon-close a, .tabledrag-handle a, .icon-help a, .icon-settings a, .icon-switch a, .icon-messages a, .icon-friends a, .icon-notifications a, .icon-back a, .icon-add a, .icon-search a, .icon-menu a, .icon-refresh a, .icon a.active, .icon-small a.active, .icon-edit a a.active, .icon-close a.active, .tabledrag-handle a.active, .icon-help a.active, .icon-settings a.active, .icon-switch a.active, .icon-messages a.active, .icon-friends a.active, .icon-notifications a.active, .icon-back a.active, .icon-add a.active, .icon-search a.active, .icon-menu a.active, .icon-refresh a.active {
    display: block;
    margin: 0 !important;
    padding: 0 !important;
    height: 2em;
    width: 2em;
    color: transparent;
    background-size: cover; }
    .icon a:hover, .icon-small a:hover, .icon-edit a a:hover, .icon-close a:hover, .tabledrag-handle a:hover, .icon-help a:hover, .icon-settings a:hover, .icon-switch a:hover, .icon-messages a:hover, .icon-friends a:hover, .icon-notifications a:hover, .icon-back a:hover, .icon-add a:hover, .icon-search a:hover, .icon-menu a:hover, .icon-refresh a:hover, .icon a:active, .icon-small a:active, .icon-edit a a:active, .icon-close a:active, .tabledrag-handle a:active, .icon-help a:active, .icon-settings a:active, .icon-switch a:active, .icon-messages a:active, .icon-friends a:active, .icon-notifications a:active, .icon-back a:active, .icon-add a:active, .icon-search a:active, .icon-menu a:active, .icon-refresh a:active, .icon a.active:hover, .icon-small a.active:hover, .icon-edit a a.active:hover, .icon-close a.active:hover, .tabledrag-handle a.active:hover, .icon-help a.active:hover, .icon-settings a.active:hover, .icon-switch a.active:hover, .icon-messages a.active:hover, .icon-friends a.active:hover, .icon-notifications a.active:hover, .icon-back a.active:hover, .icon-add a.active:hover, .icon-search a.active:hover, .icon-menu a.active:hover, .icon-refresh a.active:hover, .icon a.active:active, .icon-small a.active:active, .icon-edit a a.active:active, .icon-close a.active:active, .tabledrag-handle a.active:active, .icon-help a.active:active, .icon-settings a.active:active, .icon-switch a.active:active, .icon-messages a.active:active, .icon-friends a.active:active, .icon-notifications a.active:active, .icon-back a.active:active, .icon-add a.active:active, .icon-search a.active:active, .icon-menu a.active:active, .icon-refresh a.active:active {
      color: transparent; }

.icon-small, .icon-edit a, .icon-close {
  width: 1.33333em;
  height: 1.33333em;
  margin: 0px 0.32813rem 0.32813rem 0; }
  .icon-small a, .icon-edit a a, .icon-close a {
    width: 1.33333em;
    height: 1.33333em; }
  @media (min-width: 60em) {
    .icon-small, .icon-edit a, .icon-close {
      margin: 0px 0.34722rem 0.34722rem 0; } }
  @media (min-width: 90em) {
    .icon-small, .icon-edit a, .icon-close {
      margin: 0px 0.325rem 0.325rem 0; } }

.tabledrag-handle {
  background-color: #659916;
  background-image: linear-gradient(#486c10, #659916, #82c61c);
  margin-right: 1em; }
  .tabledrag-handle:hover {
    background-image: linear-gradient(#659916, #82c61c, #9ce233); }
  .tabledrag-handle .handle {
    background-image: url(../images/icons/icon-draggable.svg);
    background-size: cover;
    background-position: center;
    height: 2em;
    width: 2em;
    margin: 0;
    padding: 0;
    border-color: #c5c2c2 #bfbbbb #aca8a8 #b5b2b2;
    border-width: 1px;
    border-style: solid;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }

.tabledrag-handle-hover .handle {
  background-position: center; }

.icon-help,
.icon-settings,
.icon-switch,
.icon-messages,
.icon-friends,
.icon-notifications {
  margin: 0.32813rem; }
  @media (min-width: 60em) {
    .icon-help,
    .icon-settings,
    .icon-switch,
    .icon-messages,
    .icon-friends,
    .icon-notifications {
      margin: 0.34722rem; } }
  @media (min-width: 90em) {
    .icon-help,
    .icon-settings,
    .icon-switch,
    .icon-messages,
    .icon-friends,
    .icon-notifications {
      margin: 0.325rem; } }

.icon-edit a {
  float: left;
  margin: 0px 0.32813rem 0.32813rem; }
  @media (min-width: 60em) {
    .icon-edit a {
      margin: 0px 0.34722rem 0.34722rem; } }
  @media (min-width: 90em) {
    .icon-edit a {
      margin: 0px 0.325rem 0.325rem; } }

.icon-back,
.icon-add,
.icon-search,
.icon-close,
.icon-menu,
.icon-refresh {
  float: left;
  background-color: transparent;
  background-image: linear-gradient(rgba(0, 0, 0, 0), transparent, rgba(26, 26, 26, 0));
  margin: 0px 0.32813rem 0.32813rem 0; }
  .icon-back:hover,
  .icon-add:hover,
  .icon-search:hover,
  .icon-close:hover,
  .icon-menu:hover,
  .icon-refresh:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(26, 26, 26, 0), rgba(51, 51, 51, 0)); }
  .icon-back a,
  .icon-add a,
  .icon-search a,
  .icon-close a,
  .icon-menu a,
  .icon-refresh a {
    border-color: #c5c2c2 #bfbbbb #aca8a8 #b5b2b2;
    border-width: 1px;
    border-style: solid;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }
  @media (min-width: 60em) {
    .icon-back,
    .icon-add,
    .icon-search,
    .icon-close,
    .icon-menu,
    .icon-refresh {
      margin: 0px 0.34722rem 0.34722rem 0; } }
  @media (min-width: 90em) {
    .icon-back,
    .icon-add,
    .icon-search,
    .icon-close,
    .icon-menu,
    .icon-refresh {
      margin: 0px 0.325rem 0.325rem 0; } }

.icon-help {
  margin: 0.32813rem 0.65626rem 0px 0;
  border-bottom: solid 1px transparent; }
  @media (min-width: 60em) {
    .icon-help {
      margin: 0.34722rem 0.69445rem 0px 0; } }
  @media (min-width: 90em) {
    .icon-help {
      margin: 0.325rem 0.65001rem 0px 0; } }

.icon-edit a {
  background-color: transparent;
  background-image: url(../images/icons/icon-edit.svg), linear-gradient(rgba(0, 0, 0, 0), transparent, rgba(26, 26, 26, 0));
  border-color: rgba(255, 255, 255, 0.1) transparent rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.05);
  border-width: 1px;
  border-style: solid;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }
  .icon-edit a:hover {
    background-image: url(../images/icons/icon-edit.svg), linear-gradient(rgba(0, 0, 0, 0), rgba(26, 26, 26, 0), rgba(51, 51, 51, 0)); }


.icon-add a {
  background-image: url(../images/icons/icon-add.svg); }

.icon-help a {
  background-image: url(../images/icons/icon-question.svg); }


.icon-search a {
  background-image: url(../images/icons/icon-search.svg); }


.icon-menu a {
  background-image: url(../images/icons/icon-menu.svg); }

.icon-settings a {
  background-image: url(../images/icons/icon-settings.svg); }

.icon-switch a {
  background-image: url(../images/icons/icon-switch.svg); }

.icon-messages a {
  background-image: url(../images/icons/icon-mail.svg); }

.icon-friends a {
  background-image: url(../images/icons/icon-friends.svg); }

.icon-notifications a {
  background-image: url(../images/icons/icon-bell.svg); }


.icon-refresh a {
  background-image: url(../images/icons/icon-refresh.svg); }


.icon-close a {
  background-image: url(../images/icons/icon-close.svg); }

.icon-close {
  float: left;
  padding: 0;
  margin: 0.32813rem; }
  @media (min-width: 60em) {
    .icon-close {
      margin: 0.34722rem; } }
  @media (min-width: 90em) {
    .icon-close {
      margin: 0.325rem; } }

.block--system-user-menu ul li, .block--system-user-menu ul li.leaf, .block--system-user-menu ul.menu li, .block--system-user-menu ul.menu li.leaf {
  float: left; }

@media print {
  form, .tabs, .field--vote, .links,
  ul.breadcrumb,
  #block-search-form,
  #navigation-wrapper {
    display: none; } }

.node .field--image img, .node img.media-image {
  max-width: 100%; }

.modal-icon-wrapper {
  float: left;
  width: 2em;
  height: 2em; }

.l-filters-wrapper {
  margin: 0.65626rem 0; }
  @media (min-width: 60em) {
    .l-filters-wrapper {
      margin: 0.69445rem 0; } }
  @media (min-width: 90em) {
    .l-filters-wrapper {
      margin: 0.65001rem 0; } }

#nav-modal-wrapper {
  margin: 0.32813rem 0.65626rem 0px 0;
  border-bottom: solid 1px transparent; }
  @media (min-width: 60em) {
    #nav-modal-wrapper {
      margin: 0.34722rem 0.69445rem 0px 0; } }
  @media (min-width: 90em) {
    #nav-modal-wrapper {
      margin: 0.325rem 0.65001rem 0px 0; } }

.js .modal-menu,
.js .l-filters {
  float: left;
  margin: 0px 0.32813rem 0.32813rem 0;
  position: relative;
  max-height: 2em;
  max-width: 2em;
  overflow: hidden; }
  @media (min-width: 60em) {
    .js .modal-menu,
    .js .l-filters {
      margin: 0px 0.34722rem 0.34722rem 0; } }
  @media (min-width: 90em) {
    .js .modal-menu,
    .js .l-filters {
      margin: 0px 0.325rem 0.325rem 0; } }
  .js .modal-menu .contextual-links-wrapper,
  .js .l-filters .contextual-links-wrapper {
    display: none !important; }

#modal-background.is-visible {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1); }

.js .l-filters.is-visible {
  width: auto;
  height: auto;
  max-width: 100%; }

.js .modal-menu.is-visible {
  position: absolute;
  width: 15em;
  max-width: 100%;
  margin-left: -0.1em;
  color: #5B5A5A;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.5), 3px 3px 15px 3px rgba(0, 0, 0, 0.1); }
  .js .modal-menu.is-visible .inner {
    text-shadow: none;
    padding: 0 8.3% 0.5em; }
  .js .modal-menu.is-visible h1, .js .modal-menu.is-visible h2, .js .modal-menu.is-visible h3, .js .modal-menu.is-visible h4, .js .modal-menu.is-visible h5 {
    color: #424242;
    margin: 0; }
  .js .modal-menu.is-visible ul.menu {
    margin: 0px 0 1em 1em;
    margin: 0 0 1em 1em; }
    @media (min-width: 60em) {
      .js .modal-menu.is-visible ul.menu {
        margin: 0px 0 1em 1em; } }
    @media (min-width: 90em) {
      .js .modal-menu.is-visible ul.menu {
        margin: 0px 0 1em 1em; } }
    .js .modal-menu.is-visible ul.menu ul.menu {
      margin: 0px 0 1.31251rem 0.32813rem; }
      @media (min-width: 60em) {
        .js .modal-menu.is-visible ul.menu ul.menu {
          margin: 0px 0 1.3889rem 0.34722rem; } }
      @media (min-width: 90em) {
        .js .modal-menu.is-visible ul.menu ul.menu {
          margin: 0px 0 1.30001rem 0.325rem; } }
    .js .modal-menu.is-visible ul.menu a {
      color: #5B5A5A;
      color: #dc7b1f;
      text-decoration: none; }
      .js .modal-menu.is-visible ul.menu a:visited {
        color: #c66f1c; }
      .js .modal-menu.is-visible ul.menu a:active, .js .modal-menu.is-visible ul.menu a:focus, .js .modal-menu.is-visible ul.menu a:hover {
        color: #e08835; }

.js .l-filters.is-visible {
  width: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }
  .js .l-filters.is-visible .inner {
    margin: 0px 0 1.96877rem;
    padding: 0px 0.65626rem; }
    @media (min-width: 60em) {
      .js .l-filters.is-visible .inner {
        margin: 0px 0 2.08335rem;
        padding: 0px 0.69445rem; } }
    @media (min-width: 90em) {
      .js .l-filters.is-visible .inner {
        margin: 0px 0 1.95002rem;
        padding: 0px 0.65001rem; } }
    .js .l-filters.is-visible .inner h2 {
      margin: 0; }

.js .l-filters.is-visible,
.js .modal-menu.is-visible {
  overflow: visible;
  max-height: 120em;
  background-color: #fff;
  border-radius: 4px;
  border-color: rgba(128, 128, 128, 0.28) rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.28) rgba(0, 0, 0, 0.24);
  border-width: 1px;
  border-style: solid;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }
  .js .l-filters.is-visible .titlebar,
  .js .modal-menu.is-visible .titlebar {
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.15)), linear-gradient(rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0));
    border-radius: 4px 4px 0 0;
    width: 100%; }
    .js .l-filters.is-visible .titlebar::after,
    .js .modal-menu.is-visible .titlebar::after {
      clear: both;
      content: "";
      display: block; }
  .js .l-filters.is-visible .titlebar-title,
  .js .modal-menu.is-visible .titlebar-title {
    display: block;
    color: white;
    font-weight: bold;
    padding: 0.39375rem 0.32813rem 0px;
    float: left; }
    @media (min-width: 60em) {
      .js .l-filters.is-visible .titlebar-title,
      .js .modal-menu.is-visible .titlebar-title {
        padding: 0.41667rem 0.34722rem 0px; } }
    @media (min-width: 90em) {
      .js .l-filters.is-visible .titlebar-title,
      .js .modal-menu.is-visible .titlebar-title {
        padding: 0.39rem 0.325rem 0px; } }

.js .modal-menu.is-visible .icon-menu,
.js .l-filters.is-visible .icon-search {
  display: none; }

html, body {
  height: 100%;
  font-size: 1rem;
  line-height: 1.31251rem;
  margin: 0px;
  padding: 0px; }
  @media (min-width: 60em) {
    html, body {
      font-size: 1rem;
      line-height: 1.3889rem;
      margin: 0px;
      padding: 0px; } }
  @media (min-width: 90em) {
    html, body {
      font-size: 1rem;
      line-height: 1.30001rem;
      margin: 0px;
      padding: 0px; } }

#page-wrapper,
.l-page,
#main-wrapper {
  min-height: 100vh; }
  #page-wrapper::after,
  .l-page::after,
  #main-wrapper::after {
    clear: both;
    content: "";
    display: block; }

.l-main {
  padding: 0.32813rem 0.32813rem 0.65626rem; }
  @media (min-width: 60em) {
    .l-main {
      padding: 0.34722rem 0.34722rem 0.69445rem; } }
  @media (min-width: 90em) {
    .l-main {
      padding: 0.325rem 0.325rem 0.65001rem; } }
  @media (min-width: 20em) {
    .l-main {
      padding: 0.65626rem 0.65626rem 1.31251rem; } }
  @media (min-width: 30em) {
    .l-main {
      padding: 1.31251rem 1.31251rem 2.62502rem; } }
  @media (min-width: 45em) {
    .l-main {
      padding: 1.31251rem 0 2.62502rem; } }
  @media (min-width: 75em) {
    .l-main {
      padding-top: 2.62502rem;
      padding-bottom: 3.93753rem; } }

.l-region--footer {
  padding: 1.31251rem; }
  @media (min-width: 60em) {
    .l-region--footer {
      padding: 1.3889rem; } }
  @media (min-width: 90em) {
    .l-region--footer {
      padding: 1.30001rem; } }

h1 {
  font-size: 1.95312rem;
  line-height: 2.62501rem;
  margin: 0.32813rem 0 1.31251rem; }
  @media (min-width: 60em) {
    h1 {
      font-size: 1.95312rem;
      line-height: 2.77779rem;
      margin: 0.34722rem 0 1.3889rem; } }
  @media (min-width: 90em) {
    h1 {
      font-size: 2.36859rem;
      line-height: 2.60001rem;
      margin: 0.325rem 0 1.30001rem; } }

h2, h3, h4, h5 {
  font-size: 1.25rem;
  line-height: 1.31251rem;
  line-height: 1.64064rem;
  margin: 0.65626rem 0;
  padding: 0.32813rem 0 0.65626rem; }
  @media (min-width: 60em) {
    h2, h3, h4, h5 {
      font-size: 1.25rem;
      line-height: 1.3889rem;
      line-height: 1.73612rem;
      margin: 0.69445rem 0;
      padding: 0.34722rem 0 0.69445rem; } }
  @media (min-width: 90em) {
    h2, h3, h4, h5 {
      font-size: 1.333rem;
      line-height: 2.60001rem;
      line-height: 1.62501rem;
      margin: 0.65001rem 0;
      padding: 0.325rem 0 0.65001rem; } }

h2 {
  font-size: 1.5625rem;
  line-height: 2.62501rem;
  line-height: 1.64064rem; }
  @media (min-width: 60em) {
    h2 {
      font-size: 1.5625rem;
      line-height: 2.77779rem;
      line-height: 1.73612rem; } }
  @media (min-width: 90em) {
    h2 {
      font-size: 1.77689rem;
      line-height: 2.60001rem;
      line-height: 1.62501rem; } }

p {
  margin: 0.65626rem 0;
  padding: 0px; }
  @media (min-width: 60em) {
    p {
      margin: 0.69445rem 0;
      padding: 0px; } }
  @media (min-width: 90em) {
    p {
      margin: 0.65001rem 0;
      padding: 0px; } }
  p:first-child {
    margin-top: 0; }

table {
  margin: 0px 0 1.31251rem; }
  @media (min-width: 60em) {
    table {
      margin: 0px 0 1.3889rem; } }
  @media (min-width: 90em) {
    table {
      margin: 0px 0 1.30001rem; } }

caption, th, td {
  padding: 0.32813rem 0.65626rem; }
  @media (min-width: 60em) {
    caption, th, td {
      padding: 0.34722rem 0.69445rem; } }
  @media (min-width: 90em) {
    caption, th, td {
      padding: 0.325rem 0.65001rem; } }

tr:first-child > * {
  padding-top: 0.5em; }

caption,
tr:last-child > * {
  padding-bottom: 0.5em; }

tr > *:first-child {
  border-left-width: 1px; }

tr > *:last-child {
  border-right-width: 1px; }

tr:first-child > * {
  border-top-width: 1px; }

tr:last-child > * {
  border-bottom-width: 1px; }

th.active img {
  margin-left: 0.5em; }

ul.breadcrumb {
  margin: 0px;
  padding: 0px; }
  @media (min-width: 60em) {
    ul.breadcrumb {
      margin: 0px;
      padding: 0px; } }
  @media (min-width: 90em) {
    ul.breadcrumb {
      margin: 0px;
      padding: 0px; } }

li {
  margin: 0;
  padding: 0; }

.l-navigation {
  padding: 0px 0 0px 0.65626rem; }
  @media (min-width: 60em) {
    .l-navigation {
      padding: 0px 0 0px 0.69445rem; } }
  @media (min-width: 90em) {
    .l-navigation {
      padding: 0px 0 0px 0.65001rem; } }

.l-region--toolbar ul li, .l-region--toolbar ul li.leaf, .l-region--toolbar ul.menu li, .l-region--toolbar ul.menu li.leaf,
.l-region--sidebar-left ul li,
.l-region--sidebar-left ul li.leaf,
.l-region--sidebar-left ul.menu li,
.l-region--sidebar-left ul.menu li.leaf,
.l-region--sidebar-right ul li,
.l-region--sidebar-right ul li.leaf,
.l-region--sidebar-right ul.menu li,
.l-region--sidebar-right ul.menu li.leaf,
.l-region--navigation ul li,
.l-region--navigation ul li.leaf,
.l-region--navigation ul.menu li,
.l-region--navigation ul.menu li.leaf {
  -webkit-transform: translateY(-1px);
  -ms-transform: translateY(-1px);
  transform: translateY(-1px);
  margin-bottom: -2px;
  margin-right: 0.32813rem; }
  @media (min-width: 60em) {
    .l-region--toolbar ul li, .l-region--toolbar ul li.leaf, .l-region--toolbar ul.menu li, .l-region--toolbar ul.menu li.leaf,
    .l-region--sidebar-left ul li,
    .l-region--sidebar-left ul li.leaf,
    .l-region--sidebar-left ul.menu li,
    .l-region--sidebar-left ul.menu li.leaf,
    .l-region--sidebar-right ul li,
    .l-region--sidebar-right ul li.leaf,
    .l-region--sidebar-right ul.menu li,
    .l-region--sidebar-right ul.menu li.leaf,
    .l-region--navigation ul li,
    .l-region--navigation ul li.leaf,
    .l-region--navigation ul.menu li,
    .l-region--navigation ul.menu li.leaf {
      -webkit-transform: translateY(-1px);
      -ms-transform: translateY(-1px);
      transform: translateY(-1px);
      margin-bottom: -2px;
      margin-right: 0.34722rem; } }
  @media (min-width: 90em) {
    .l-region--toolbar ul li, .l-region--toolbar ul li.leaf, .l-region--toolbar ul.menu li, .l-region--toolbar ul.menu li.leaf,
    .l-region--sidebar-left ul li,
    .l-region--sidebar-left ul li.leaf,
    .l-region--sidebar-left ul.menu li,
    .l-region--sidebar-left ul.menu li.leaf,
    .l-region--sidebar-right ul li,
    .l-region--sidebar-right ul li.leaf,
    .l-region--sidebar-right ul.menu li,
    .l-region--sidebar-right ul.menu li.leaf,
    .l-region--navigation ul li,
    .l-region--navigation ul li.leaf,
    .l-region--navigation ul.menu li,
    .l-region--navigation ul.menu li.leaf {
      -webkit-transform: translateY(-1px);
      -ms-transform: translateY(-1px);
      transform: translateY(-1px);
      margin-bottom: -2px;
      margin-right: 0.325rem; } }
  .l-region--toolbar ul li a, .l-region--toolbar ul li.leaf a, .l-region--toolbar ul.menu li a, .l-region--toolbar ul.menu li.leaf a,
  .l-region--sidebar-left ul li a,
  .l-region--sidebar-left ul li.leaf a,
  .l-region--sidebar-left ul.menu li a,
  .l-region--sidebar-left ul.menu li.leaf a,
  .l-region--sidebar-right ul li a,
  .l-region--sidebar-right ul li.leaf a,
  .l-region--sidebar-right ul.menu li a,
  .l-region--sidebar-right ul.menu li.leaf a,
  .l-region--navigation ul li a,
  .l-region--navigation ul li.leaf a,
  .l-region--navigation ul.menu li a,
  .l-region--navigation ul.menu li.leaf a {
    margin: 0.32813rem 0;
    padding: 0.32813rem 0.65626rem; }
    @media (min-width: 60em) {
      .l-region--toolbar ul li a, .l-region--toolbar ul li.leaf a, .l-region--toolbar ul.menu li a, .l-region--toolbar ul.menu li.leaf a,
      .l-region--sidebar-left ul li a,
      .l-region--sidebar-left ul li.leaf a,
      .l-region--sidebar-left ul.menu li a,
      .l-region--sidebar-left ul.menu li.leaf a,
      .l-region--sidebar-right ul li a,
      .l-region--sidebar-right ul li.leaf a,
      .l-region--sidebar-right ul.menu li a,
      .l-region--sidebar-right ul.menu li.leaf a,
      .l-region--navigation ul li a,
      .l-region--navigation ul li.leaf a,
      .l-region--navigation ul.menu li a,
      .l-region--navigation ul.menu li.leaf a {
        margin: 0.34722rem 0;
        padding: 0.34722rem 0.69445rem; } }
    @media (min-width: 90em) {
      .l-region--toolbar ul li a, .l-region--toolbar ul li.leaf a, .l-region--toolbar ul.menu li a, .l-region--toolbar ul.menu li.leaf a,
      .l-region--sidebar-left ul li a,
      .l-region--sidebar-left ul li.leaf a,
      .l-region--sidebar-left ul.menu li a,
      .l-region--sidebar-left ul.menu li.leaf a,
      .l-region--sidebar-right ul li a,
      .l-region--sidebar-right ul li.leaf a,
      .l-region--sidebar-right ul.menu li a,
      .l-region--sidebar-right ul.menu li.leaf a,
      .l-region--navigation ul li a,
      .l-region--navigation ul li.leaf a,
      .l-region--navigation ul.menu li a,
      .l-region--navigation ul.menu li.leaf a {
        margin: 0.325rem 0;
        padding: 0.325rem 0.65001rem; } }

.l-main .block {
  margin-bottom: 1.31251rem; }
  @media (min-width: 60em) {
    .l-main .block {
      margin-bottom: 1.3889rem; } }
  @media (min-width: 90em) {
    .l-main .block {
      margin-bottom: 1.30001rem; } }

.l-content .block {
  margin-bottom: 2.62502rem; }
  @media (min-width: 60em) {
    .l-content .block {
      margin-bottom: 2.7778rem; } }
  @media (min-width: 90em) {
    .l-content .block {
      margin-bottom: 2.60002rem; } }

ul.tabs--primary {
  font-size: 0.8rem;
  line-height: 1.31251rem;
  display: inline-block;
  margin: 0px 0 calc((1.31251rem) - 2px);
  display: block;
  border-bottom-width: 2px; }
  @media (min-width: 60em) {
    ul.tabs--primary {
      font-size: 0.8rem;
      line-height: 1.3889rem;
      display: inline-block;
      margin: 0px 0 calc((1.3889rem) - 2px); } }
  @media (min-width: 90em) {
    ul.tabs--primary {
      font-size: 0.75019rem;
      line-height: 1.30001rem;
      display: inline-block;
      margin: 0px 0 calc((1.30001rem) - 2px); } }
  ul.tabs--primary li:first-child {
    margin-left: 0.16406rem; }
    @media (min-width: 60em) {
      ul.tabs--primary li:first-child {
        margin-left: 0.17361rem; } }
    @media (min-width: 90em) {
      ul.tabs--primary li:first-child {
        margin-left: 0.1625rem; } }
  ul.tabs--primary li {
    margin: 0;
    margin-right: 0.16406rem; }
    @media (min-width: 60em) {
      ul.tabs--primary li {
        margin-right: 0.17361rem; } }
    @media (min-width: 90em) {
      ul.tabs--primary li {
        margin-right: 0.1625rem; } }

form {
  margin-top: 0;
  margin-bottom: 0; }

fieldset {
  width: 100%;
  margin: 0;
  -webkit-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  transform: translateY(-2px);
  display: inline-block;
  margin: 0.65626rem 0 calc((1.31251rem) - 4px); }
  @media (min-width: 60em) {
    fieldset {
      -webkit-transform: translateY(-2px);
      -ms-transform: translateY(-2px);
      transform: translateY(-2px);
      display: inline-block;
      margin: 0.69445rem 0 calc((1.3889rem) - 4px); } }
  @media (min-width: 90em) {
    fieldset {
      -webkit-transform: translateY(-2px);
      -ms-transform: translateY(-2px);
      transform: translateY(-2px);
      display: inline-block;
      margin: 0.65001rem 0 calc((1.30001rem) - 4px); } }

.fieldset-wrapper {
  margin: 0;
  padding: 0.65626rem; }
  @media (min-width: 60em) {
    .fieldset-wrapper {
      padding: 0.69445rem; } }
  @media (min-width: 90em) {
    .fieldset-wrapper {
      padding: 0.65001rem; } }

legend {
  margin-left: 0.65626rem;
  padding: 0px 0.32813rem; }
  @media (min-width: 60em) {
    legend {
      margin-left: 0.69445rem;
      padding: 0px 0.34722rem; } }
  @media (min-width: 90em) {
    legend {
      margin-left: 0.65001rem;
      padding: 0px 0.325rem; } }

tr .form-item,
.form-item {
  padding: 0;
  margin: 0.32813rem 0 0.65626rem; }
  @media (min-width: 60em) {
    tr .form-item,
    .form-item {
      margin: 0.34722rem 0 0.69445rem; } }
  @media (min-width: 90em) {
    tr .form-item,
    .form-item {
      margin: 0.325rem 0 0.65001rem; } }
  tr .form-item::after,
  .form-item::after {
    clear: both;
    content: "";
    display: block; }
  tr .form-item label,
  .form-item label {
    margin: 0.32813rem 0; }
    @media (min-width: 60em) {
      tr .form-item label,
      .form-item label {
        margin: 0.34722rem 0; } }
    @media (min-width: 90em) {
      tr .form-item label,
      .form-item label {
        margin: 0.325rem 0; } }

.form-checkboxes .form-item, .form-radios .form-item {
  width: auto; }
  .form-checkboxes .form-item input, .form-radios .form-item input {
    width: 1.25em;
    margin: 0.32813rem; }
    @media (min-width: 60em) {
      .form-checkboxes .form-item input, .form-radios .form-item input {
        margin: 0.34722rem; } }
    @media (min-width: 90em) {
      .form-checkboxes .form-item input, .form-radios .form-item input {
        margin: 0.325rem; } }

.form-textarea-wrapper textarea,
textarea {
  height: 6.56255rem; }
  @media (min-width: 60em) {
    .form-textarea-wrapper textarea,
    textarea {
      height: 6.94449rem; } }
  @media (min-width: 90em) {
    .form-textarea-wrapper textarea,
    textarea {
      height: 6.50005rem; } }

.form-type-textarea {
  -webkit-transform: translateY(-1px);
  -ms-transform: translateY(-1px);
  transform: translateY(-1px);
  margin-bottom: -2px; }
  @media (min-width: 60em) {
    .form-type-textarea {
      -webkit-transform: translateY(-1px);
      -ms-transform: translateY(-1px);
      transform: translateY(-1px);
      margin-bottom: -2px; } }
  @media (min-width: 90em) {
    .form-type-textarea {
      -webkit-transform: translateY(-1px);
      -ms-transform: translateY(-1px);
      transform: translateY(-1px);
      margin-bottom: -2px; } }

.grippie, .resizable-textarea .grippie {
  width: 100%;
  height: calc(0.65626rem + 2px);
  -webkit-transform: translateY(-1px);
  -ms-transform: translateY(-1px);
  transform: translateY(-1px);
  display: inline-block;
  margin-top: 0;
  margin-bottom: calc((0.98438rem) - 2px); }
  @media (min-width: 60em) {
    .grippie, .resizable-textarea .grippie {
      height: calc(0.69445rem + 2px);
      -webkit-transform: translateY(-1px);
      -ms-transform: translateY(-1px);
      transform: translateY(-1px);
      display: inline-block;
      margin-top: 0;
      margin-bottom: calc((1.04167rem) - 2px); } }
  @media (min-width: 90em) {
    .grippie, .resizable-textarea .grippie {
      height: calc(0.65001rem + 2px);
      -webkit-transform: translateY(-1px);
      -ms-transform: translateY(-1px);
      transform: translateY(-1px);
      display: inline-block;
      margin-top: 0;
      margin-bottom: calc((0.97501rem) - 2px); } }

.description, .reload-captcha-wrapper, .form-item .description, .form-item .reload-captcha-wrapper, .form-actions .description, .form-actions .reload-captcha-wrapper {
  font-size: 0.8rem;
  line-height: 1.31251rem;
  line-height: 1.05001rem;
  margin: 0px 0 0.65626rem;
  padding: 0.16406rem 0 0.49219rem; }
  @media (min-width: 60em) {
    .description, .reload-captcha-wrapper, .form-item .description, .form-item .reload-captcha-wrapper, .form-actions .description, .form-actions .reload-captcha-wrapper {
      font-size: 0.8rem;
      line-height: 1.3889rem;
      line-height: 1.11112rem;
      margin: 0px 0 0.69445rem;
      padding: 0.17361rem 0 0.52084rem; } }
  @media (min-width: 90em) {
    .description, .reload-captcha-wrapper, .form-item .description, .form-item .reload-captcha-wrapper, .form-actions .description, .form-actions .reload-captcha-wrapper {
      font-size: 0.75019rem;
      line-height: 1.30001rem;
      line-height: 1.04001rem;
      margin: 0px 0 0.65001rem;
      padding: 0.1625rem 0 0.4875rem; } }

textarea, form-textarea-wrapper textarea {
  padding: 0px 0.16406rem; }
  @media (min-width: 60em) {
    textarea, form-textarea-wrapper textarea {
      padding: 0px 0.17361rem; } }
  @media (min-width: 90em) {
    textarea, form-textarea-wrapper textarea {
      padding: 0px 0.1625rem; } }

/*
input, select, button, [type='button'], [type='reset'], [type='submit'], .button, // Correct vertical rhythm reset settings
input:not([type=radio]):not([type=checkbox]), .chosen-container-single .chosen-single:not([type=radio]):not([type=checkbox]), form#privatemsg-list div.container-inline select:not([type=radio]):not([type=checkbox]), option, select:not([multiple]) {
*/
input {
  line-height: 1.64064rem;
  height: 1.64064rem;
  margin-right: 0.16406rem;
  padding: 0px 0.16406rem; }
  @media (min-width: 60em) {
    input {
      line-height: 1.73612rem;
      height: 1.73612rem;
      margin-right: 0.17361rem;
      padding: 0px 0.17361rem; } }
  @media (min-width: 90em) {
    input {
      line-height: 1.62501rem;
      height: 1.62501rem;
      margin-right: 0.1625rem;
      padding: 0px 0.1625rem; } }
  input:focus {
    border-width: 2px; }

.button, button, .form-actions a, [type='button'], [type='reset'], [type='submit'], input[type=submit] {
  font-size: 0.8rem;
  line-height: 1.31251rem;
  line-height: 1.31251rem;
  margin: 0.32813rem 0.65626rem 0.32813rem 0;
  padding: 0 1.5em;
  width: auto; }
  @media (min-width: 60em) {
    .button, button, .form-actions a, [type='button'], [type='reset'], [type='submit'], input[type=submit] {
      font-size: 0.8rem;
      line-height: 1.3889rem;
      line-height: 1.3889rem;
      margin: 0.34722rem 0.69445rem 0.34722rem 0; } }
  @media (min-width: 90em) {
    .button, button, .form-actions a, [type='button'], [type='reset'], [type='submit'], input[type=submit] {
      font-size: 0.75019rem;
      line-height: 1.30001rem;
      line-height: 1.30001rem;
      margin: 0.325rem 0.65001rem 0.325rem 0; } }

button, .form-actions a,
input:not([type="radio"]):not([type="checkbox"]),
option,
select:not([multiple]) {
  height: 1.64064rem; }
  @media (min-width: 60em) {
    button, .form-actions a,
    input:not([type="radio"]):not([type="checkbox"]),
    option,
    select:not([multiple]) {
      height: 1.73612rem; } }
  @media (min-width: 90em) {
    button, .form-actions a,
    input:not([type="radio"]):not([type="checkbox"]),
    option,
    select:not([multiple]) {
      height: 1.62501rem; } }

option {
  height: 1.31251rem; }
  @media (min-width: 60em) {
    option {
      height: 1.3889rem; } }
  @media (min-width: 90em) {
    option {
      height: 1.30001rem; } }

input[type="radio"],
input[type="checkbox"] {
  margin: 0;
  margin-right: 0.16406rem;
  padding-bottom: 0.32813rem; }
  @media (min-width: 60em) {
    input[type="radio"],
    input[type="checkbox"] {
      margin-right: 0.17361rem;
      padding-bottom: 0.34722rem; } }
  @media (min-width: 90em) {
    input[type="radio"],
    input[type="checkbox"] {
      margin-right: 0.1625rem;
      padding-bottom: 0.325rem; } }

.form-checkboxes .form-item, .form-radios .form-item {
  margin: 0; }

.form-checkboxes .form-item:last-child,
.form-radios .form-item:last-child {
  margin-bottom: 1.31251rem; }
  @media (min-width: 60em) {
    .form-checkboxes .form-item:last-child,
    .form-radios .form-item:last-child {
      margin-bottom: 1.3889rem; } }
  @media (min-width: 90em) {
    .form-checkboxes .form-item:last-child,
    .form-radios .form-item:last-child {
      margin-bottom: 1.30001rem; } }

input[type=file] {
  padding: 0;
  line-height: 1.31251rem;
  padding-left: 0.32813rem; }
  @media (min-width: 60em) {
    input[type=file] {
      line-height: 1.3889rem;
      padding-left: 0.34722rem; } }
  @media (min-width: 90em) {
    input[type=file] {
      line-height: 1.30001rem;
      padding-left: 0.325rem; } }

.login-forgot {
  margin-bottom: 2.62502rem; }
  @media (min-width: 60em) {
    .login-forgot {
      margin-bottom: 2.7778rem; } }
  @media (min-width: 90em) {
    .login-forgot {
      margin-bottom: 2.60002rem; } }

.password-strength {
  margin-bottom: 2.62502rem; }
  @media (min-width: 60em) {
    .password-strength {
      margin-bottom: 2.7778rem; } }
  @media (min-width: 90em) {
    .password-strength {
      margin-bottom: 2.60002rem; } }
  .password-strength .password-strength-title {
    margin-bottom: 1.31251rem; }
    @media (min-width: 60em) {
      .password-strength .password-strength-title {
        margin-bottom: 1.3889rem; } }
    @media (min-width: 90em) {
      .password-strength .password-strength-title {
        margin-bottom: 1.30001rem; } }

.node--page .field--name-field-images .field__item {
  margin-bottom: 1.31251rem; }
  @media (min-width: 60em) {
    .node--page .field--name-field-images .field__item {
      margin-bottom: 1.3889rem; } }
  @media (min-width: 90em) {
    .node--page .field--name-field-images .field__item {
      margin-bottom: 1.30001rem; } }

.messages, .toboggan-unified #login-message {
  max-width: 70em;
  -webkit-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  transform: translateY(-2px);
  display: inline-block;
  margin: 0.65626rem auto calc((0.65626rem) - 4px);
  padding: 0.32813rem 0.65626rem 0.65626rem 2.29689rem; }
  @media (min-width: 60em) {
    .messages, .toboggan-unified #login-message {
      -webkit-transform: translateY(-2px);
      -ms-transform: translateY(-2px);
      transform: translateY(-2px);
      display: inline-block;
      margin: 0.69445rem auto calc((0.69445rem) - 4px);
      padding: 0.34722rem 0.69445rem 0.69445rem 2.43057rem; } }
  @media (min-width: 90em) {
    .messages, .toboggan-unified #login-message {
      -webkit-transform: translateY(-2px);
      -ms-transform: translateY(-2px);
      transform: translateY(-2px);
      display: inline-block;
      margin: 0.65001rem auto calc((0.65001rem) - 4px);
      padding: 0.325rem 0.65001rem 0.65001rem 2.27502rem; } }
  .messages:last-child, .toboggan-unified #login-message:last-child {
    margin-bottom: 1em; }

blockquote {
  font-size: 1.25rem;
  line-height: 1.31251rem;
  line-height: 1.96877rem;
  margin: 1.31251rem 4%;
  padding: 1.31251rem; }
  @media (min-width: 60em) {
    blockquote {
      font-size: 1.25rem;
      line-height: 1.3889rem;
      line-height: 2.08335rem;
      margin: 1.3889rem 4%;
      padding: 1.3889rem; } }
  @media (min-width: 90em) {
    blockquote {
      font-size: 1.333rem;
      line-height: 2.60001rem;
      line-height: 1.95002rem;
      margin: 1.30001rem 4%;
      padding: 1.30001rem; } }

address {
  padding-bottom: 1.31251rem; }
  @media (min-width: 60em) {
    address {
      padding-bottom: 1.3889rem; } }
  @media (min-width: 90em) {
    address {
      padding-bottom: 1.30001rem; } }

dd {
  margin-bottom: 0.65626rem; }
  @media (min-width: 60em) {
    dd {
      margin-bottom: 0.69445rem; } }
  @media (min-width: 90em) {
    dd {
      margin-bottom: 0.65001rem; } }

pre {
  margin: 0;
  padding: 0px 0 0.65626rem; }
  @media (min-width: 60em) {
    pre {
      padding: 0px 0 0.69445rem; } }
  @media (min-width: 90em) {
    pre {
      padding: 0px 0 0.65001rem; } }

.superscript,
sup {
  font-size: 0.8rem;
  line-height: 1.31251rem;
  padding: 0.16406rem 0 0px; }
  @media (min-width: 60em) {
    .superscript,
    sup {
      font-size: 0.8rem;
      line-height: 1.3889rem;
      padding: 0.17361rem 0 0px; } }
  @media (min-width: 90em) {
    .superscript,
    sup {
      font-size: 0.75019rem;
      line-height: 1.30001rem;
      padding: 0.1625rem 0 0px; } }

.inline .field__label {
  float: left;
  width: 30%;
  text-align: right; }

.inline .field__items {
  margin-left: 30%; }

.inline .field__item {
  margin-left: 0.5em; }

.inline-fieldgroup {
  display: inline-block; }

.tabledrag-toggle-weight-wrapper {
  font-size: 0.8rem;
  line-height: 1.31251rem; }
  @media (min-width: 60em) {
    .tabledrag-toggle-weight-wrapper {
      font-size: 0.8rem;
      line-height: 1.3889rem; } }
  @media (min-width: 90em) {
    .tabledrag-toggle-weight-wrapper {
      font-size: 0.75019rem;
      line-height: 1.30001rem; } }

iframe {
  width: 100%; }

h1:first-letter, h2:first-letter, h3:first-letter, h4:first-letter, h5:first-letter {
  text-transform: capitalize; }

a, .tabs--secondary a {
  cursor: pointer;
  border-radius: 4px;
  text-decoration: none;
  user-select: none; }

ul {
  list-style-type: disc; }

ol {
  list-style-type: decimal; }

ol, ul {
  list-style-position: outside;
  margin-left: 1.25em; }

blockquote {
  border: solid 1px #b1aa96;
  border-left: 0.5em solid #b1aa96;
  quotes: "“" "”" "‘" "’";
  text-align: left; }

blockquote:before {
  color: #b1aa96;
  content: open-quote;
  font-size: 3em;
  line-height: 0.1em;
  vertical-align: -0.4em;
  font-family: "charter", "Georgia", "Times", "Times New Roman", serif;
  text-align: left; }

video, img, picture {
  max-width: 100%;
  height: auto; }

.superscript {
  vertical-align: top; }

.hidden {
  display: none; }

sup {
  display: inline-block;
  vertical-align: top; }

.l-region--toolbar::after, .l-region--navigation::after, .l-main::after, article::after, .l-region--footer::after {
  clear: both;
  content: "";
  display: block; }

.block, .view {
  word-wrap: break-word; }
  .block::after, .view::after {
    clear: both;
    content: "";
    display: block; }

.messages, .toboggan-unified #login-message {
  border-radius: 4px;
  width: 100%; }

#author {
  text-align: center; }

.hidden {
  display: none; }

table {
  display: table;
  width: 100%;
  max-width: 100%;
  overflow: auto;
  border-collapse: seperate; }

tr {
  border-style: solid; }

tr .form-item {
  white-space: normal; }

th, td {
  vertical-align: top; }

caption {
  border-radius: 4px 4px 0 0; }

.menu .leaf {
  list-style-image: none;
  list-style-type: none; }
  .menu .leaf a {
    padding: 0; }

@media (min-width: 30em) {
  .block--system-main-menu {
    float: left;
    width: auto; } }

.block--system-user-menu {
  float: right;
  width: auto;
  padding-left: 0.5em; }

.l-region--toolbar ul, .l-region--toolbar ul.menu,
.l-region--navigation ul,
.l-region--navigation ul.menu {
  margin: 0;
  padding: 0;
  clear: left; }
  .l-region--toolbar ul li, .l-region--toolbar ul li.leaf, .l-region--toolbar ul.menu li, .l-region--toolbar ul.menu li.leaf,
  .l-region--navigation ul li,
  .l-region--navigation ul li.leaf,
  .l-region--navigation ul.menu li,
  .l-region--navigation ul.menu li.leaf {
    list-style-type: none;
    list-style-image: none;
    border-bottom: solid 1px #c1c1c1; }
    .l-region--toolbar ul li a, .l-region--toolbar ul li.leaf a, .l-region--toolbar ul.menu li a, .l-region--toolbar ul.menu li.leaf a,
    .l-region--navigation ul li a,
    .l-region--navigation ul li.leaf a,
    .l-region--navigation ul.menu li a,
    .l-region--navigation ul.menu li.leaf a {
      display: block;
      border-radius: 4px; }
  @media (min-width: 20em) {
    .l-region--toolbar ul, .l-region--toolbar ul.menu,
    .l-region--navigation ul,
    .l-region--navigation ul.menu {
      clear: none;
      padding: 0; }
      .l-region--toolbar ul::after, .l-region--toolbar ul.menu::after,
      .l-region--navigation ul::after,
      .l-region--navigation ul.menu::after {
        clear: both;
        content: "";
        display: block; }
      .l-region--toolbar ul li, .l-region--toolbar ul li.leaf, .l-region--toolbar ul.menu li, .l-region--toolbar ul.menu li.leaf,
      .l-region--navigation ul li,
      .l-region--navigation ul li.leaf,
      .l-region--navigation ul.menu li,
      .l-region--navigation ul.menu li.leaf {
        border-color: transparent; }
      .l-region--toolbar ul li, .l-region--toolbar ul li.leaf a, .l-region--toolbar ul.menu li, .l-region--toolbar ul.menu li.leaf a,
      .l-region--navigation ul li,
      .l-region--navigation ul li.leaf a,
      .l-region--navigation ul.menu li,
      .l-region--navigation ul.menu li.leaf a {
        float: left; } }

.l-region--nav-modal ul li.active-trail, .l-region--nav-modal ul.menu li.active-trail {
  list-style-type: disc; }

ul.tabs--primary {
  display: block; }
  ul.tabs--primary li {
    float: left; }
    
    ul.tabs--primary li a {
      border-radius: 4px 4px 0 0; }

div.vertical-tabs {
  border: 0; }
  div.vertical-tabs ul.vertical-tabs-list, div.vertical-tabs .vertical-tabs-list {
    border: 0; }
    div.vertical-tabs ul.vertical-tabs-list li, div.vertical-tabs .vertical-tabs-list li {
      border: 0; }
      div.vertical-tabs ul.vertical-tabs-list li a, div.vertical-tabs .vertical-tabs-list li a {
        border-radius: 4px 0 0 4px; }
  div.vertical-tabs fieldset.vertical-tabs-pane {
    border: 0; }

.links--inline {
  width: 100%; }
  .links--inline li.node-readmore,
  .links--inline li.statistics_counter {
    float: right; }

ul.breadcrumb, ul.pager {
  margin: 1.31251rem 0; }
  @media (min-width: 60em) {
    ul.breadcrumb, ul.pager {
      margin: 1.3889rem 0; } }
  @media (min-width: 90em) {
    ul.breadcrumb, ul.pager {
      margin: 1.30001rem 0; } }
  ul.breadcrumb li, ul.pager li {
    display: inline; }
  ul.breadcrumb li:after, ul.pager li:after {
    display: inline;
    content: '»';
    font-weight: bold;
    margin: 0 0.2em; }

fieldset, input, select, textarea {
  border-radius: 4px;
  max-width: 100%; }

fieldset {
  width: 100%; }

select {
  overflow-x: hidden;
  overflow-y: auto; }

fieldset.vertical-tabs-pane {
  border-radius: 0 4px 4px; }

.fieldset-wrapper {
  max-width: 100%; }
  .fieldset-wrapper::after {
    clear: both;
    content: "";
    display: block; }

table input {
  max-width: 100%; }

button, .form-actions a,
[type="button"],
[type="reset"],
[type="submit"] {
  display: inline-block;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap; }

a#back-button {
  float: left; }

span.back-button:after {
  float: left;
  display: inline;
  content: "|";
  font-weight: bold;
  margin: 0 0.5em; }

.icon, .icon-small, .icon-edit a, .icon-close, .tabledrag-handle, .icon-help,
.icon-settings,
.icon-switch,
.icon-messages,
.icon-friends,
.icon-notifications, .icon-back,
.icon-add,
.icon-search,
.icon-menu,
.icon-refresh {
  z-index: 200; }

.js .modal-menu,
.js .l-filters {
  z-index: 20; }

.js .modal-menu.is-visible {
  z-index: 21; }

#modal-background.is-visible {
  z-index: 15; }

html body.admin-menu {
  margin-top: 2.62502rem; }
  @media (min-width: 60em) {
    html body.admin-menu {
      margin-top: 2.7778rem; } }
  @media (min-width: 90em) {
    html body.admin-menu {
      margin-top: 2.60002rem; } }

#admin-menu-wrapper, #admin-menu {
  font-size: 0.64rem;
  line-height: 1.31251rem; }
  @media (min-width: 60em) {
    #admin-menu-wrapper, #admin-menu {
      font-size: 0.64rem;
      line-height: 1.3889rem; } }
  @media (min-width: 90em) {
    #admin-menu-wrapper, #admin-menu {
      font-size: 0.56278rem;
      line-height: 1.30001rem; } }
  #admin-menu-wrapper li, #admin-menu li {
    font-size: 0.64rem;
    line-height: 1.31251rem; }
    @media (min-width: 60em) {
      #admin-menu-wrapper li, #admin-menu li {
        font-size: 0.64rem;
        line-height: 1.3889rem; } }
    @media (min-width: 90em) {
      #admin-menu-wrapper li, #admin-menu li {
        font-size: 0.56278rem;
        line-height: 1.30001rem; } }

#admin-menu .dropdown .admin-menu-toolbar-category > a, #admin-menu .dropdown .tabs--secondary .admin-menu-toolbar-category > a, #admin-menu .dropdown .admin-menu-action > a, #admin-menu .dropdown .tabs--secondary .admin-menu-action > a {
  border-radius: 4px; }

a.ckeditor_links {
  display: block;
  clear: both;
  font-size: 0.8rem;
  line-height: 1.31251rem; }
  @media (min-width: 60em) {
    a.ckeditor_links {
      font-size: 0.8rem;
      line-height: 1.3889rem; } }
  @media (min-width: 90em) {
    a.ckeditor_links {
      font-size: 0.75019rem;
      line-height: 1.30001rem; } }

.better-comments .form-item {
  margin: 0; }

.better-comments div[id*="edit-comment-body"] {
  width: 100%; }

.better-comments-confirm, .preview {
  box-shadow: none;
  min-height: auto;
  padding: 0;
  width: auto; }

div.comment-body {
  width: 100%;
  border-radius: 4px;
  margin: 0px 0 1.31251rem; }
  @media (min-width: 60em) {
    div.comment-body {
      margin: 0px 0 1.3889rem; } }
  @media (min-width: 90em) {
    div.comment-body {
      margin: 0px 0 1.30001rem; } }
  div.comment-body a.username {
    display: block;
    width: 100%;
    clear: both; }
  div.comment-body .field--images {
    float: left;
    margin-right: 4%;
    max-width: 40%; }

.indented .comment-body {
  width: 100%; }

div[class*="indented"] {
  width: 96%;
  margin-left: 4%; }

.comment__links {
  font-size: 0.8rem;
  line-height: 1.31251rem; }
  @media (min-width: 60em) {
    .comment__links {
      font-size: 0.8rem;
      line-height: 1.3889rem; } }
  @media (min-width: 90em) {
    .comment__links {
      font-size: 0.75019rem;
      line-height: 1.30001rem; } }

.comment-data time {
  font-style: italic;
  display: block;
  width: 100%;
  clear: both;
  margin: 1.31251rem 0 0px; }
  @media (min-width: 60em) {
    .comment-data time {
      margin: 1.3889rem 0 0px; } }
  @media (min-width: 90em) {
    .comment-data time {
      margin: 1.30001rem 0 0px; } }

.comment-data time {
  font-size: 0.8rem;
  line-height: 1.31251rem; }

a.ctools-ajaxing,
input.ctools-ajaxing,
button.ctools-ajaxing,
.form-actions a.ctools-ajaxing,
select.ctools-ajaxing {
  padding-right: 18px !important;
  background: url(../images/misc/throbber-animated.svg) right center no-repeat; }

div.ctools-ajaxing {
  float: left;
  width: 18px;
  background: url(../images/misc/throbber-animated.svg) center center no-repeat; }

html.js .form-autocomplete {
  background-image: none;
  background-image: url("../images/misc/throbber.svg");
  background-position: 100% 0%;
  background-repeat: no-repeat; }

html.js input.throbbing {
  background-image: url("../images/misc/throbber-animated.svg");
  background-position: 100% 0%; }

div.toboggan-container {
  text-align: center; }

div.toboggan-login-link-container {
  text-align: left; }

div.toboggan-login-link-container a {
  text-decoration: none; }

div.toboggan-block-message {
  text-align: left; }

div.user-login-block {
  text-align: left; }

div.user-login-block a {
  text-align: left; }

/* Unified login */
.toboggan-unified #login-message {
  text-align: center; }

.toboggan-unified, .login {
  font-size: 1rem;
  line-height: 1.31251rem; }
  @media (min-width: 60em) {
    .toboggan-unified, .login {
      font-size: 1rem;
      line-height: 1.3889rem; } }
  @media (min-width: 90em) {
    .toboggan-unified, .login {
      font-size: 1rem;
      line-height: 1.30001rem; } }
  .toboggan-unified #login-message, .login #login-message {
    font-size: 1.25rem;
    line-height: 1.31251rem; }
    @media (min-width: 60em) {
      .toboggan-unified #login-message, .login #login-message {
        font-size: 1.25rem;
        line-height: 1.3889rem; } }
    @media (min-width: 90em) {
      .toboggan-unified #login-message, .login #login-message {
        font-size: 1.333rem;
        line-height: 2.60001rem; } }
  .toboggan-unified #login-links a.active, .login #login-links a.active {
    font-weight: normal; }
  .toboggan-unified #login-links a.lt-active, .login #login-links a.lt-active {
    font-weight: bold; }
  .toboggan-unified #login-links a,
  .toboggan-unified #login-links a:hover, .toboggan-unified #login-links a.lt-active, .login #login-links a,
  .login #login-links a:hover, .login #login-links a.lt-active {
    text-align: center;
    display: inline-block;
    font-size: 1.25rem;
    line-height: 1.31251rem;
    color: #FFFAE4 !important;
    background-color: transparent !important;
    border: solid 1px #b1aa96 !important;
    margin-bottom: 0.65626rem; }
    @media (min-width: 60em) {
      .toboggan-unified #login-links a,
      .toboggan-unified #login-links a:hover, .toboggan-unified #login-links a.lt-active, .login #login-links a,
      .login #login-links a:hover, .login #login-links a.lt-active {
        font-size: 1.25rem;
        line-height: 1.3889rem; } }
    @media (min-width: 90em) {
      .toboggan-unified #login-links a,
      .toboggan-unified #login-links a:hover, .toboggan-unified #login-links a.lt-active, .login #login-links a,
      .login #login-links a:hover, .login #login-links a.lt-active {
        font-size: 1.333rem;
        line-height: 2.60001rem; } }

.toboggan-unified #login-links a {
  background-color: transparent;
  color: #dc7b1f; }


.toboggan-unified #login-links a:hover {
  background-color: transparent;
  color: #e08835; }

.toboggan-unified #login-links a.lt-active,
.toboggan-unified #login-links a.lt-active:hover {
  background-color: transparent;
  color: #dc7b1f; }

.toboggan-unified.login #register-form {
  display: none; }

.toboggan-unified.register #login-form {
  display: none; }

.block--user-login {
  margin: 0;
  border: 0;
  line-height: 1.333;
  float: right; }
  .block--user-login a {
    display: block;
    padding: 0.125em 0.5em;
    transition: background-color 150ms ease; }
    .block--user-login a:hover {
      background-color: rgba(255, 255, 255, 0.1); }
  .block--user-login .user-login-block {
    border: solid 1px #b1aa96;
    padding-top: 0.25em;
    padding-left: 0.5em; }

div.edit-password-wrapper {
  display: none; }

div.edit-default-wrapper {
  display: none; }

div.edit-rev-wrapper {
  display: none; }

.views-ui-display-tab-actions .ctools-button-processed li a,
.views-ui-display-tab-actions .ctools-button-processed input {
  color: #5B5A5A; }

/*
 * _views-filters.scss 
 * Greenman Webdesigns
*/
.view-filters form {
  margin: 0; }

@media (min-width: 60em) {
  .view-filters .form-type-bef-checkbox {
    text-align: center;
    width: 21%;
    margin: 2%; }
    .view-filters .form-type-bef-checkbox:nth-child(3n+1) {
      clear: none; }
    .view-filters .form-type-bef-checkbox:nth-child(4n+1) {
      clear: left; } }

.views-exposed-form {
  margin: 0.65626rem 0;
  padding: 0.65626rem; }
  @media (min-width: 60em) {
    .views-exposed-form {
      margin: 0.69445rem 0;
      padding: 0.69445rem; } }
  @media (min-width: 90em) {
    .views-exposed-form {
      margin: 0.65001rem 0;
      padding: 0.65001rem; } }
  .views-exposed-form .views-exposed-widget {
    width: 100%;
    max-width: 100%;
    margin: 0;
    margin-bottom: 0.65626rem; }
    @media (min-width: 60em) {
      .views-exposed-form .views-exposed-widget {
        margin-bottom: 0.69445rem; } }
    @media (min-width: 90em) {
      .views-exposed-form .views-exposed-widget {
        margin-bottom: 0.65001rem; } }
    @media (min-width: 30em) {
      .views-exposed-form .views-exposed-widget {
        width: 46%;
        margin: 2%; }
        .views-exposed-form .views-exposed-widget:nth-child(1n+1) {
          clear: none; }
        .views-exposed-form .views-exposed-widget:nth-child(2n+1) {
          clear: left; } }
    @media (min-width: 45em) {
      .views-exposed-form .views-exposed-widget {
        width: 29.33333%;
        margin: 2%; }
        .views-exposed-form .views-exposed-widget:nth-child(2n+1) {
          clear: none; }
        .views-exposed-form .views-exposed-widget:nth-child(3n+1) {
          clear: left; } }
    @media (min-width: 60em) {
      .views-exposed-form .views-exposed-widget {
        width: 21%;
        margin: 2%; }
        .views-exposed-form .views-exposed-widget:nth-child(3n+1) {
          clear: none; }
        .views-exposed-form .views-exposed-widget:nth-child(4n+1) {
          clear: left; } }
    @media (min-width: 75em) {
      .views-exposed-form .views-exposed-widget {
        width: 12.66667%;
        margin: 2%; }
        .views-exposed-form .views-exposed-widget:nth-child(4n+1) {
          clear: none; }
        .views-exposed-form .views-exposed-widget:nth-child(6n+1) {
          clear: left; } }
    @media (min-width: 90em) {
      .views-exposed-form .views-exposed-widget {
        width: 8.5%;
        margin: 2%; }
        .views-exposed-form .views-exposed-widget:nth-child(6n+1) {
          clear: none; }
        .views-exposed-form .views-exposed-widget:nth-child(8n+1) {
          clear: left; } }
    .views-exposed-form .views-exposed-widget select, .views-exposed-form .views-exposed-widget input:not([type=submit]) {
      width: 100%; }
  .views-exposed-form .views-reset-button,
  .views-exposed-form .views-submit-button {
    width: auto;
    float: left; }
  .views-exposed-form .form-submit {
    margin-top: 1.31251rem; }
    @media (min-width: 60em) {
      .views-exposed-form .form-submit {
        margin-top: 1.3889rem; } }
    @media (min-width: 90em) {
      .views-exposed-form .form-submit {
        margin-top: 1.30001rem; } }

.views-exposed-widgets {
  margin: 0; }

.views-exposed-form .views-exposed-widget {
  padding: 0; }
  .views-exposed-form .views-exposed-widget .form-submit {
    margin-top: 1.31251rem; }
    @media (min-width: 60em) {
      .views-exposed-form .views-exposed-widget .form-submit {
        margin-top: 1.3889rem; } }
    @media (min-width: 90em) {
      .views-exposed-form .views-exposed-widget .form-submit {
        margin-top: 1.30001rem; } }

.contact {
  display: absolute;
  bottom: 0;
  padding: 2em; }
  .contact .phone, .contact .fax, .contact .address {
    float: left;
    margin-right: 2em;
    font-size: 1.25rem;
    line-height: 1.31251rem; }
    @media (min-width: 60em) {
      .contact .phone, .contact .fax, .contact .address {
        font-size: 1.25rem;
        line-height: 1.3889rem; } }
    @media (min-width: 90em) {
      .contact .phone, .contact .fax, .contact .address {
        font-size: 1.333rem;
        line-height: 2.60001rem; } }

.contact-form-info img {
  float: right;
  margin: 1em; }

@media (min-width: 60em) {
  .contact {
    float: right; }
    .contact .phone, .contact .fax, .contact .address {
      float: none; } }

.giftvoucher img, .giftvoucher a {
  max-height: rhythm(4);
  width: auto;
  margin: 0px;
  padding: 0px; }

.logo {
  float: left;
  max-width: 100%; }

@media (min-width: 60em) {
  .logo {
    max-width: 60%; } }

.social {
  float: right;
  margin: 1em; }

#bookeo_position iframe {
  height: 1158px;
  min-width: 640px; }

.booking-form-link {
  font-size: 0.8rem;
  line-height: 1.31251rem; }
  @media (min-width: 60em) {
    .booking-form-link {
      font-size: 0.8rem;
      line-height: 1.3889rem; } }
  @media (min-width: 90em) {
    .booking-form-link {
      font-size: 0.75019rem;
      line-height: 1.30001rem; } }

.node--teaser {
  margin-bottom: 1.31251rem; }
  @media (min-width: 60em) {
    .node--teaser {
      margin-bottom: 1.3889rem; } }
  @media (min-width: 90em) {
    .node--teaser {
      margin-bottom: 1.30001rem; } }
  .node--teaser .field--image {
    float: left; }
    .node--teaser .field--image a {
      display: block;
      height: 96px;
      margin: 0;
      padding: 0; }
  .node--teaser .field-body p {
    text-align: justify; }
  .node--teaser img {
    float: left;
    margin: 0 1em 0.5em 0; }

.node--full .field--image img, .node--full img.media-image {
  float: left;
  margin: 0 1em 0.5em 0; }

.node--page--full img,
.node--practitioner--full img,
.node--therapy--full img {
  margin: 0 1em 0.5em 0;
  margin: 1em; }

#header-wrapper {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)), url(../images/flowers.svg), url(../images/leaf-bg.jpg);
  background-size: auto 100%, cover, cover;
  background-repeat: no-repeat;
  background-position: right, center;
  text-shadow: -1px 1px 2px #000, 0 0 1px #000;
  font-weight: bold; }
  #header-wrapper .contextual-links {
    text-shadow: none; }

.l-branding {
  font-family: "charter", "Georgia", "Times", "Times New Roman", serif; }

#navigation-wrapper {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)); }

.cke_inner {
  height: 100% !important; }
  .cke_inner::after {
    clear: both;
    content: "";
    display: block; }

.field--embedded-media .field__item {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin: 0px 0 0.65626rem; }
  @media (min-width: 60em) {
    .field--embedded-media .field__item {
      margin: 0px 0 0.69445rem; } }
  @media (min-width: 90em) {
    .field--embedded-media .field__item {
      margin: 0px 0 0.65001rem; } }
  .field--embedded-media .field__item iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.flexslider {
  margin: 0;
  border: 0;
  box-shadow: none; }
  .flexslider::after {
    clear: both;
    content: "";
    display: block; }

.flex-control-thumbs {
  margin: 0;
  height: 12em;
  overflow-y: auto;
  resize: both;
  margin: 0.32813rem 0; }
  @media (min-width: 60em) {
    .flex-control-thumbs {
      margin: 0.34722rem 0; } }
  @media (min-width: 90em) {
    .flex-control-thumbs {
      margin: 0.325rem 0; } }
  .flex-control-thumbs li {
    padding: 0.32813rem 0.16406rem; }
    @media (min-width: 60em) {
      .flex-control-thumbs li {
        padding: 0.34722rem 0.17361rem; } }
    @media (min-width: 90em) {
      .flex-control-thumbs li {
        padding: 0.325rem 0.1625rem; } }

.flex-control-paging li a.flex-active {
  background-color: #659916; }

.media-list-thumbnails .media-item.selected {
  background-color: #4DAE83; }

.block--search .form-item, .block--search .form-item input {
  width: 100%; }

.block--search .form-actions {
  margin: auto; }

.field-slideshow-controls {
  text-align: center; }

.field-slideshow {
  margin: 0; }

.field-slideshow-caption {
  font-size: 0.8rem;
  line-height: 1.31251rem;
  line-height: 1.05001rem;
  margin: 0px 0 0.65626rem;
  text-align: center; }
  @media (min-width: 60em) {
    .field-slideshow-caption {
      font-size: 0.8rem;
      line-height: 1.3889rem;
      line-height: 1.11112rem;
      margin: 0px 0 0.69445rem; } }
  @media (min-width: 90em) {
    .field-slideshow-caption {
      font-size: 0.75019rem;
      line-height: 1.30001rem;
      line-height: 1.04001rem;
      margin: 0px 0 0.65001rem; } }

.views-field-field-video-file {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 51%; }
  .views-field-field-video-file .video-js,
  .views-field-field-video-file video {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0; }

/*
 * _module-views-breakpoints.scss
 * Greenman Webdesigns
 * Nice default settings for views
 */
.view-noticeboard .views-row {
  margin: 2%;
  float: left; }
  @media (min-width: 45em) {
    .view-noticeboard .views-row {
      width: 46%;
      margin: 2%; }
      .view-noticeboard .views-row:nth-child(1n+1) {
        clear: none; }
      .view-noticeboard .views-row:nth-child(2n+1) {
        clear: left; } }
  @media (min-width: 60em) {
    .view-noticeboard .views-row {
      width: 29.33333%;
      margin: 2%; }
      .view-noticeboard .views-row:nth-child(2n+1) {
        clear: none; }
      .view-noticeboard .views-row:nth-child(3n+1) {
        clear: left; } }
  @media (min-width: 90em) {
    .view-noticeboard .views-row {
      width: 21%;
      margin: 2%; }
      .view-noticeboard .views-row:nth-child(3n+1) {
        clear: none; }
      .view-noticeboard .views-row:nth-child(4n+1) {
        clear: left; } }
  @media (min-width: 120em) {
    .view-noticeboard .views-row {
      width: 16%;
      margin: 2%; }
      .view-noticeboard .views-row:nth-child(4n+1) {
        clear: none; }
      .view-noticeboard .views-row:nth-child(5n+1) {
        clear: left; } }
