.contact {
	display: absolute;
	bottom: 0;
	padding: 2em;
	.phone, .fax, .address {
		float: left;
		margin-right: 2em;
		@include vr(
			$font-size: 1
		);
	}
}

.contact-form-info {
	img {
		float: right;
		margin: 1em;
	}
}

@include media($normal) {
	.contact {
		float: right;
		.phone, .fax, .address {
			float: none;
		}
	}
}
