@mixin background-color-gradient($color) {
	$color-light: lighten($color, $lighten);
	background-color: $color;

	$color-first: adjust-hue(darken($color, $darken), -1 * $darken);
	$color-last: adjust-hue(lighten($color, $lighten), $lighten);

	background-image: linear-gradient($color-first, $color, $color-last);
}

@mixin background-color-hover-gradient($color) {
	$color-light: lighten($color, $lighten);
	background-color: $color;
	background-image: linear-gradient(darken($color, $darken), $color, lighten($color, $lighten));
	&:hover {
		background-image: linear-gradient(darken($color-light, $darken), $color-light, lighten($color-light, $lighten));
	}
}

@mixin background-color-image-gradient($color, $image-url) {
	$color-light: lighten($color, $lighten);
	background-color: $color;
	background-image: $image-url, linear-gradient(darken($color, $darken), $color, lighten($color, $lighten));
	}

@mixin background-color-image-hover-gradient($color, $image-url) {
	$color-light: lighten($color, $lighten);
	background-color: $color;
	background-image: $image-url, linear-gradient(darken($color, $darken), $color, lighten($color, $lighten));
	&:hover {
		background-image: $image-url, linear-gradient(darken($color-light, $darken), $color-light, lighten($color-light, $lighten));
	}
}
