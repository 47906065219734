//
// _typography-base.scss
// Greenman Webdesigns
//

//
// Element styles
//

h1, h2, h3, h4, h5 {
	&:first-letter {
		text-transform: capitalize;
	}
}

a {
  cursor: pointer;
  border-radius: $border-radius;
  text-decoration: none;
  user-select: none;
}

ul {
	list-style-type: disc;
}
ol {
	list-style-type: decimal;
}
ol, ul {
	list-style-position: outside;
	margin-left: 1.25em;
}

blockquote {
	border: $border;
  border-left: 0.5em solid $border-color;
  quotes: "\201C""\201D""\2018""\2019";
	text-align: left;
}

blockquote:before {
  color: $border-color;
  content: open-quote;
  font-size: 3em;
  line-height: 0.1em;
  vertical-align: -0.4em;
	font-family: $serif;
	text-align: left;
}

video, img, picture {
  max-width: 100%;
	height: auto;
}

//
// Styles
//
.superscript {
	vertical-align: top;
}

.hidden {
  display: none;
}

sup {
	display: inline-block;
	vertical-align: top;
}

//
// Regions
//
.l-region--toolbar, .l-region--navigation, .l-main, article, .l-region--footer {
	@include clearfix;
}
//.l-region--sidebar-first {
//  .block {
//		width: 100%;
 // }
//}
.block, .view {
	@include clearfix;
	word-wrap: break-word;
}	
// Float gracefully below images etc. to avoid orphans
//.node p:before {
//  content: "";
//  width: 8em;
//  display: block;
//  overflow: hidden;
//}
.messages {
	border-radius: $border-radius;
	width: 100%;
}
#author {
	text-align: center;
}
.hidden {
	display: none;
}


//
// Tables
//
table {
	display: table;
	width: 100%;
	max-width: 100%;
	overflow: auto;
	border-collapse: seperate;
}
tr {
  border-style: solid;
}

tr .form-item {
	white-space: normal;
}

th, td {
	vertical-align: top;
}
caption {
	border-radius: $border-radius $border-radius 0 0;
}
//thead tr:first-child {
//	*:first-child {
//		border-radius: $border-radius 0 0 0;
//	}
//	*:last-child {
//		border-radius: 0 $border-radius 0 0;
//	}
//}
//tbody tr:last-child {
//	> *:first-child {
//		border-radius: 0 0 0 $border-radius;
//	}
//	> *:last-child {
//		border-radius: 0 0 $border-radius 0;
//	}
//}
//th.active img { // Icon: sort arrow
//  border-radius: 2px;
//}


//
// Menus
//
.menu {
	.leaf {
		list-style-image: none;
		list-style-type: none;
		a {
			padding: 0;
		}
	}
}

.block--system-main-menu {
	@include media($small) {
		float: left;
		width: auto;
	}
}
.block--system-user-menu {
//	@include media($small) {
		float: right;
		width: auto;
		padding-left: $base-spacing;
//	}
}

.l-region--toolbar,
.l-region--navigation {
	ul, ul.menu {
		margin: 0;
		padding: 0;
	  li, li.leaf {
	    list-style-type: none;
	    list-style-image: none;
			border-bottom: solid 1px $navigation-border-color;
			a {
				display: block;
//				width: 100%;
				border-radius: $border-radius;
			}
		}
		clear: left;
		@include media($tiny) {
			clear: none;
			@include clearfix;
			padding: 0;

			li, li.leaf {
				border-color: transparent;
			}
			li,li.leaf	a {
				float: left;
			}
	  }
	}
}
.l-region--nav-modal {
  ul, ul.menu {
    li.active-trail {
      list-style-type: disc;
    }
  }
}


ul.tabs--primary {
	display: block;
  li {
		float: left;
    a {
			border-radius: $border-radius $border-radius 0 0;
    }
  }
}


div.vertical-tabs {
	border: 0;
	ul.vertical-tabs-list, .vertical-tabs-list {
		border: 0;
		li {
			border: 0;
			a {
				border-radius: $border-radius 0 0 $border-radius;
			}
		}
	}
	fieldset.vertical-tabs-pane {
		border: 0;
	}
}

.links--inline {
//	clear: both;
	width: 100%;
	li.node-readmore, 
	li.statistics_counter {
		float: right;
	}
}

ul.breadcrumb, ul.pager {
	@include vr(
		$margin: 1 0
	);
	li {
		display: inline;
	}
	li:after {
		display: inline;
		content: '»';
		font-weight: bold;
		margin: 0 0.2em;
	}
}
// Ensure shadow styles don't inherit in context menus
//.contextual-links-wrapper {
//	text-shadow: none;
//}

//
// Forms and fields
//
fieldset, input, select, textarea {
	border-radius: $border-radius;
	max-width: 100%;
}
fieldset {
	width: 100%;
}
select {
	overflow-x: hidden;
	overflow-y: auto;
}
fieldset.vertical-tabs-pane {
	border-radius: 0 $border-radius $border-radius;
}
.fieldset-wrapper {
	max-width: 100%;
	@include clearfix;
}

// Fixes input field bleed over in tables
table input {
	max-width: 100%;
}
// Draggable field images

//tr.draggable {
//	min-width: 200px;
//}

//.tabledrag-handle .handle {
//	margin: 0;
//	padding: 0;
//	cursor: pointer;
//}
//.tabledrag-handle-hover .handle {
//	background-position: 0px -20px;
//}

	
// Buttons
#{$all-buttons} {
	display: inline-block;
	cursor: pointer;
  border-radius: $border-radius;
	text-align: center;
	text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.form-actions a {
	@extend button;
}

a#back-button {
	float: left;
}
span.back-button:after {
	float: left;
	display: inline;
	content: "|";
	font-weight: bold;
	margin: 0 0.5em;
}
