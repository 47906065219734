html.js .form-autocomplete {
  background-image: none;
  background-image: url('../images/misc/throbber.svg');
  background-position: 100% 0%;
  background-repeat: no-repeat;
}

html.js input.throbbing {
  background-image: url('../images/misc/throbber-animated.svg');
  background-position: 100% 0%;
}
